import { ArrowBackIos, } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material'
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout'
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { createActivityValidationSchema, updateActivityValidationSchema } from 'utils/validations';
import dayjs from 'dayjs';
import { TextField, SelectField, DateField, RichTextField } from 'components';
import { categories, departments } from 'utils';
import { useAdminActivityQuery, useUpdateActivityGeneralMutation } from 'apps/dashboard/context';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';


const UpdateActivity = () => {

    const { id } = useParams() as { id: string }
    const navigate = useNavigate()
    const { data: activityData } = useAdminActivityQuery({ id: id ?? '' })
    const [updateActivityGeneral, { data, isSuccess, isLoading, isError, error }] = useUpdateActivityGeneralMutation()

    console.log(activityData, 'activityData')
    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched } = useFormik({
        initialValues: {
            title: activityData?.title ?? '',
            category: activityData?.letsTitle,
            letsTitle: activityData?.letsTitle,
            quota: activityData?.mainQuota ?? '',
            date: dayjs(activityData?.date) ?? null,
            reservationCloseDate: dayjs(activityData?.reservationCloseDate) ?? null,
            location: activityData?.location ?? '',
            reserveQuota: activityData?.reserveQuota ?? '',
            details: activityData?.details ?? '',
        },
        enableReinitialize: true,
        validationSchema: updateActivityValidationSchema,
        onSubmit: values => {
            console.log(values, 'zzzz')
            updateActivityGeneral({
                activityId: activityData?.activityId?._id ?? '',
                title: values.title,
                mainQuota: Number(values.quota),
                reserveQuota: Number(values.reserveQuota),
                categories: values.category,
                date: values.date.toDate() ?? new Date(),
                reservationCloseDate: values.reservationCloseDate.toDate() ?? new Date(),
                location: values.location,
                details: values.details,
                letsTitle: values.letsTitle
            })
        }
    })

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar('Activity updated successfully', { variant: 'success' })
            navigate(-1)
        }
    }, [isSuccess, navigate])

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('An error occurred while updating the activity', { variant: 'error' })
        }
    }, [isError])


    return <DashboardLayout>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <ArrowBackIos onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }} />
                        <h1>Update Activity</h1>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12} xl={11}>
                <Box sx={{ py: 2, px: 4, mb: 5, mt: 3, bgcolor: 'primary.lighter', borderRadius: 2 }}>
                    <Typography variant='body1' >
                        Attention! When you update the general information of the activity, all sessions are affected.
                    </Typography>
                </Box>
                <Card sx={{ p: 5 }}>
                    <Typography variant="h6">Activity Information</Typography>
                    <Divider />
                    <Grid container spacing={3} mt={1}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Title"
                                values={values}
                                name={"title"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Location"
                                values={values}
                                name={"location"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label="Category"
                                values={values}
                                name={"category"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                                datas={categories}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Quota"
                                variant="outlined"
                                values={values}
                                name={"quota"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Reserve Quota"
                                variant="outlined"
                                values={values}
                                name={"reserveQuota"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateField
                                label="Date"
                                values={values}
                                minDate={dayjs(new Date())}
                                maxDate={dayjs(new Date()).add(1, 'year')}
                                name="date"
                                setFieldValue={setFieldValue}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DateField
                                label="Reservation Close Date"
                                values={values}
                                minDate={dayjs(new Date())}
                                maxDate={dayjs(new Date()).add(1, 'year')}
                                name="reservationCloseDate"
                                setFieldValue={setFieldValue}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RichTextField
                                values={values}
                                name={"details"}
                                setFieldValue={setFieldValue}
                                placeholder="Details..."
                            />
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={isLoading || !values.title || !values.category || !values.quota || !values.date || !values.reservationCloseDate || !values.location || !values.details}
                                onClick={() => {
                                    handleSubmit()
                                }}
                            >
                                {isLoading ? <CircularProgress size="1rem" /> : 'Update Activity'}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>

            </Grid>
        </Grid>
    </DashboardLayout>
}

export default UpdateActivity