
export const categories = [
    {
        value: "letsRelax",
        label: "Let’s Relax"
    },
    {
        value: "letsTry",
        label: "Let’s Try"
    },
    {
        value: "letsLearn",
        label: "Let’s Learn"
    },
    {
        value: "letsMove",
        label: "Let’s Move"
    },
    {
        value: "letsConnect",
        label: "Let’s Connect"
    }
]


export const departments = [
    {
        value: 'QSC',
        label: 'QSC'
    },
    {
        value: 'AREA',
        label: 'AREA'
    },
    {
        value: 'GCC',
        label: 'GCC'
    }
]

export const activityStatus = [
    {
        value: 'canceled',
        label: 'Canceled'
    },
    {
        value: 'completed',
        label: 'Completed'
    },
    {
        value: 'expired',
        label: 'Expired'
    },
    {
        value: 'inProgress',
        label: 'In Progress'
    },
    {
        value: 'pending',
        label: 'Pending'
    },
    {
        value: 'scheduled',
        label: 'Scheduled'
    }
]