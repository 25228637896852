import React from 'react';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout';
import { useUserStatisticsQuery, useUserActivitiesQuery } from 'apps/dashboard/context';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import { format, subYears, eachMonthOfInterval, addMonths } from 'date-fns';
import Lottie from 'lottie-react';
import PieChartAnimation from 'assets/animations/piechart.json';
import BarChartAnimation from 'assets/animations/barchart.json';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF4563'];

const STATUS_COLORS = {
    pending: '#FFA500',
    completed: '#00C49F',
    inProgress: '#0088FE',
    scheduled: '#9ACD32',
};

type ActivityStatus = 'pending' | 'completed' | 'inProgress' | 'scheduled';

interface MonthlyData {
    month: string;
    pending: number;
    completed: number;
    inProgress: number;
    scheduled: number;
}

const Statistics = () => {
    const { data: userStatisticsData, error: userStatisticsError, isLoading: userStatisticsIsLoading } = useUserStatisticsQuery({
        filter: {},
    });

    const { data: userActivitiesData, error: userActivitiesError, isLoading: userActivitiesIsLoading } = useUserActivitiesQuery({
        filter: {
            date: {
                from: new Date(format(subYears(new Date(), 1), 'yyyy-MM-dd')),
                to: new Date(format(new Date(), 'yyyy-MM-dd'))
            },
        },
    });
    
    if (userStatisticsIsLoading || userActivitiesIsLoading) return <DashboardLayout>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h1">Statistics</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" textAlign={"center"} mt={3}><i>Loading...</i></Typography>
            </Grid>
        </Grid>
    </DashboardLayout>;
    if (userStatisticsError || userActivitiesError) return <DashboardLayout>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h1">Statistics</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Card sx={{ padding: 2 }}>
                    <Typography variant="h4" textAlign={"center"}>User Statistics</Typography>

                    <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="h6" textAlign={"center"} mt={3}><i>No data available</i></Typography>
                        <Box sx={{ width: 300, height: 300, mt: -3 }}>
                            <Lottie animationData={PieChartAnimation} loop={true} />
                        </Box>
                    </Stack>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card sx={{ padding: 2 }}>
                    <Typography variant="h4" textAlign={"center"} mb={2}>Monthly Activities</Typography>

                    <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="h6" textAlign={"center"} mt={3}><i>No data available</i></Typography>
                        <Box sx={{ width: 300, height: 300, mb: -5 }}>
                            <Lottie animationData={BarChartAnimation} loop={true} />
                        </Box>
                    </Stack>
                </Card>
            </Grid>
        </Grid>
    </DashboardLayout>;

    const userStatisticsPieData = Object.keys((userStatisticsData as any)?.data?.user_statistics || {}).map((key) => ({
        name: key.charAt(0).toUpperCase() + key.slice(1),
        value: (userStatisticsData as any)?.data?.user_statistics[key],
    }));

    // Aylık bazda gruplama ve durumlara göre renk ayırma
    const monthlyData: MonthlyData[] = eachMonthOfInterval({
        start: subYears(new Date(), 1),
        end: addMonths(new Date(), 1),
    }).map((date) => ({
        month: format(date, 'yyyy-MM'),
        pending: 0,
        completed: 0,
        inProgress: 0,
        scheduled: 0,
    }));

    (userActivitiesData as any)?.data?.forEach((activity: any) => {
        const activityDate = new Date(activity.date);
        const activityMonth = format(activityDate, 'yyyy-MM');
        const monthData = monthlyData.find((data) => data.month === activityMonth);
        if (monthData && (activity.status in monthData)) {
            if (activity.status !== 'canceled' && activity.status !== 'expired') {
                monthData[activity.status as ActivityStatus] += 1; // Aktivite sayısını duruma göre artır
            }
        }
    });

    return (
        <DashboardLayout>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h1">Statistics</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{ padding: 2 }}>
                        <Typography variant="h4" textAlign={"center"}>User Statistics</Typography>
                        <ResponsiveContainer width="100%" height={400}>
                            <PieChart>
                                <Pie
                                    data={userStatisticsPieData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {userStatisticsPieData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{ padding: 2 }}>
                        <Typography variant="h4" textAlign={"center"}>Monthly Activities</Typography>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart
                                data={monthlyData}
                                margin={{
                                    top: 5, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="pending" stackId="a" fill={STATUS_COLORS.pending} name="Pending" />
                                <Bar dataKey="completed" stackId="a" fill={STATUS_COLORS.completed} name="Completed" />
                                <Bar dataKey="inProgress" stackId="a" fill={STATUS_COLORS.inProgress} name="In Progress" />
                                <Bar dataKey="scheduled" stackId="a" fill={STATUS_COLORS.scheduled} name="Scheduled" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default Statistics;
