import React from 'react'
import { Box, Grid, Typography, Container } from '@mui/material'
import AuthImage from 'assets/auth-img.png'


export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  return <Container>
    <Grid container>
      <Grid item xs={12} md={6} display={{ xs: 'none', md: 'flex' }} alignItems={'center'}>
        <Box
          sx={{
            width: '90%',
            height: "80vh",
            // borderRadius: 10,
            backgroundColor: "#33424F",
            backgroundImage: "radial-gradient(ellipse farthest-corner at 4px 4px, #2f3d49, #2f3d49 50%, #33424F 50%)",
            backgroundSize: "4px 4px",
            borderRadius: 10,
            // maskImage: 'paint(smooth-corners)',
            // WebkitMaskImage: 'paint(smooth-corners)',
            // '--smooth-corners': 10,
          }}
          position={"relative"}
          p={7}
        >
          <Typography color={"#fff"} textAlign={"center"} fontSize={30} fontWeight={600} lineHeight={1.6}>
            Are you ready to meet the
          </Typography>
          <Typography color={"#fff"} textAlign={"center"} fontSize={40} fontWeight={600} lineHeight={1.2}>
            Umbrella Project?
          </Typography>
          <img src={AuthImage} alt="auth" width={"70%"} style={{ position: "absolute", bottom: 0, left: '15%' }} />
        </Box>


      </Grid>
   


      {children}


  </Grid>
  </Container >

}
