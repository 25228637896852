import { MenuOpen, Menu } from '@mui/icons-material';
import ProfileBox from './ProfileBox'
import { Stack, Box, Tooltip, IconButton } from '@mui/material'
import Logo from 'assets/hapag-logo.png'
import { NavSectionVertical } from "minimal/components/nav-section";



type Props = {
    handleClick: (e: any) => void;
    anchorEl: any;
    open: boolean;
    handleClose: () => void;
    handleAccount: () => void;
    handleLogout: () => void;
    navData: any;
    handleSettings: () => void;
    isNavbarMini?: boolean;
    toggleNavbarSize?: () => void;
};

const NavDefault = ({ handleClick, anchorEl, open, handleClose, handleAccount, handleLogout, navData, handleSettings, isNavbarMini, toggleNavbarSize }: Props) => {



    return (
        <Stack
            sx={{
                width: isNavbarMini ? '80px' : '280px',
                flexShrink: 0,
                height: '100%',
                backgroundSize: 'cover',
                backgroundColor: '#FFF',
                borderRight: '2px solid #dedede',
            }}
        >

            <Box sx={{ flex: 1 }}>

                <Box sx={{ mb: 2, mt: 4, ml: 4 }}>
                    <img width={"80%"} src={Logo} alt="logo" />
                </Box>

                <NavSectionVertical
                    sx={{ display: 'flex', flex: 1, mt: 5 }}
                    data={navData}
                    config={{
                        currentRole: 'admin',
                    }}
                />

                {/* <Tooltip title={isNavbarMini ? "Expand" : "Collapse"}>
                    <IconButton onClick={toggleNavbarSize} sx={{ alignSelf: isNavbarMini ? 'center' : 'flex-end', mb: 2 }}>
                        {isNavbarMini ? <MenuOpen /> : <Menu />}
                    </IconButton>
                </Tooltip> */}

            </Box>

            <ProfileBox
                handleClick={handleClick}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                handleAccount={handleAccount}
                handleLogout={handleLogout}
                handleSettings={handleSettings}
            />

        </Stack>
    )

}

export default NavDefault