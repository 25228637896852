import { Box, CircularProgress, Stack } from '@mui/material'
import Logo from "assets/hapag-logo.png";


export const LoadingPage = () => {




    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            height="100vh"
            gap={3}
        >


            <Box
                component="img"
                src={Logo}
                height={40}
                mb={2}
            />


            <CircularProgress />

        </Stack>
    )
}
