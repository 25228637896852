

export const routes = {
    admin: {
        dashboard: "/",
        activities: "/activities",
        users: "/users",
        statistics: "/statistics",
        activityDetail: "/activities/:id",
        createActivity: "/activities/create",
        updateActivity: "/activities/update/:id",
        updateActivitySession: "/activities/update-session/:id",
        profile: "/my-profile",
        settings: "/settings"
    },
    user: {
        dashboard: "/",
        activities: "/activities",
        statistics: "/statistics",
        applications: "/applications",
        activityDetail: "/activities/:id",
        profile: "/my-profile",
        calendar : "/calendar",
        settings: "/settings",
    },
}