import { Box, Button, Container, Grid, TextField, Typography, Link, CircularProgress, FormHelperText, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Logo from "assets/hapag-logo.png";
import AuthImage from "assets/auth-img.png";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { createPasswordValidationSchema } from 'utils/validations';
import { routes } from "../routes/routes";
import { AuthLayout } from "../layouts";
import { useLocation, useNavigate } from "react-router-dom";
import { useConfirmRegistrationByUserMutation, useResendRegistrationByUserConfirmationMutation } from "../context";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { MuiOtpInput } from "mui-one-time-password-input";

const CreatePassword = () => {

  // linkte geçen token propunu almak için useLocation hookunu kullanıyoruz.

  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get('token');
  const reset = new URLSearchParams(location.search).get('reset');
  const [confirmRegistrationByUser, { data, isLoading, isError, isSuccess, error }] = useConfirmRegistrationByUserMutation();
  const [resendRegistrationByUserConfirmation, { data: resendData, isLoading: resendLoading, isError: resendError, isSuccess: resendSuccess, error: resendErrorData }] = useResendRegistrationByUserConfirmationMutation();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');


  const { values, handleSubmit, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    validationSchema: createPasswordValidationSchema,
    initialValues: {
      code: '',
      password: '',
      passwordAgain: '',
    },
    onSubmit: (values) => {
      confirmRegistrationByUser({
        token: token as string,
        password: values.password,
        resetPasswordToken: reset as string,
        code: values.code
      })
    }
  });

  const handleResendRequest = () => {
    resendRegistrationByUserConfirmation({ email: email })
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Password has been reset successfully', { variant: 'success' })
      navigate(routes.auth.login)
    }
  }, [isSuccess, navigate])

  useEffect(() => {
    if (isError) {
      enqueueSnackbar((error as any)?.error?.message.en, { variant: 'error' })
    }
  }, [isError, error])

  useEffect(() => {
    if (resendSuccess) {
      enqueueSnackbar('Email has been sent successfully', { variant: 'success' })
      setOpen(false)
      navigate(routes.auth.login)
    }
  }, [resendSuccess, navigate])

  useEffect(() => {
    if (resendError) {
      enqueueSnackbar((resendErrorData as any)?.error?.message.en, { variant: 'error' })
    }
  }, [resendError, resendErrorData])




  return <AuthLayout>
    <Grid item xs={12} md={6} display={"flex"} height={"100vh"} justifyContent={'center'} alignItems={'center'}>
         <Box display={{ xs: 'flex', md: 'none' }} height={100} />
      <Box
        sx={{
          width: '90%',
          height: "80vh",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'center' },
          alignItems: 'center',
          gap: 5,
        }}
        px={{ xs: 1, md: 7 }}
      >
        <img src={Logo} alt="logo" width={220} />
        <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"}>
          <Typography fontSize={40} fontWeight={600} textAlign={"center"}>
            Create Password
          </Typography>

        </Box>

        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
          <Typography fontSize={20} fontWeight={400} textAlign={"left"} mb={2}>
            Please enter the code sent to your email
          </Typography>


          <MuiOtpInput
            value={values.code}
            onChange={(value) => setFieldValue('code', value)}
            autoFocus
            gap={1.5}
            length={6}
            TextFieldsProps={{
              error: errors.code ? true : false,
              placeholder: '-',
            }}
          />
          <FormHelperText error={errors.code ? true : false}>{errors.code}</FormHelperText>
        </Box>



        <Box display={"flex"} sx={{ width: '100%' }} flexDirection={"column"} gap={2} alignItems={"flex-start"}>
          <Typography fontSize={20} fontWeight={400} textAlign={"left"} mb={0}>
            Please enter your password
          </Typography>
          <TextField
            placeholder="Password"
            type="password"
            fullWidth
            value={values.password}
            onChange={(e) => setFieldValue('password', e.target.value)}
            error={errors.password ? true : false}
            helperText={errors.password}
          />
          <TextField
            placeholder="Password Again"
            type="password"
            fullWidth
            value={values.passwordAgain}
            onChange={(e) => setFieldValue('passwordAgain', e.target.value)}
            error={errors.passwordAgain ? true : false}
            helperText={errors.passwordAgain}
          />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={handleSubmit as any}
            disabled={isLoading || values.code?.length !== 6 || !values.password || !values.passwordAgain || values.password !== values.passwordAgain}
          >
            {isLoading ? <CircularProgress size={"1rem"} /> :
              "Reset Password"}
          </Button>

          <Typography fontSize={15} fontWeight={500} textAlign={"center"} width={'100%'}>

            If you did not receive the email, please <Link sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)} color={"#33424F"} fontWeight={"bold"} underline={"hover"} textAlign={"center"}>resend the request.  </Link>

          </Typography>
          <Typography fontSize={15} fontWeight={500} textAlign={"center"} width={'100%'}>
            <Link href={routes.auth.login} color={"#33424F"} underline={"hover"} textAlign={"center"}>
              Back to Login
            </Link>
          </Typography>

        </Box>
      </Box>
    </Grid>

    <Dialog open={open} onClose={() => setOpen(false)}
      sx={{
        '& .MuiDialog-paper': {
          padding: 2,
          borderRadius: 2
        }
      }}>
      <DialogTitle>
        Resend Request
      </DialogTitle>
      <DialogContent sx={{ padding: 4, minWidth: 400 }}>
        <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"flex-start"}>
          <Typography fontSize={"medium"} fontWeight={400} textAlign={"left"} mb={2}>
            Please enter your email address
          </Typography>
          <TextField
            placeholder="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ mx: 2 }}
          size="large"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          sx={{ mx: 2 }}
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleResendRequest}
          disabled={resendLoading || !email}
        >
          {resendLoading ? <CircularProgress size={"1rem"} /> :
            "Resend"}
        </Button>
      </DialogActions>
    </Dialog>

  </AuthLayout>
}

export default CreatePassword;
