import React from 'react';
import { Box, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout';
import { useLetsTitleStatisticsQuery, useStatusStatisticsQuery } from 'apps/dashboard/context';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PieChartAnimation from 'assets/animations/piechart.json';
import BarChartAnimation from 'assets/animations/barchart.json';
import Lottie from 'lottie-react';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF4563'];

const Statistics = () => {
    const { data: statusData, error: statusError, isLoading: statusIsLoading } = useStatusStatisticsQuery({ filter: {} });
    const { data: letsTitleData, error: letsTitleError, isLoading: letsTitleIsLoading } = useLetsTitleStatisticsQuery({ filter: {} });



    if (statusIsLoading || letsTitleIsLoading) return <DashboardLayout>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h1">Statistics</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" textAlign={"center"} mt={3}><i>Loading...</i></Typography>
            </Grid>
        </Grid>
    </DashboardLayout>;
    if (statusError || letsTitleError) return <DashboardLayout>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h1">Statistics</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Card sx={{ padding: 2 }}>
                    <Typography variant="h4" textAlign={"center"}>Activities</Typography>

                    <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="h6" textAlign={"center"} mt={3}><i>No data available</i></Typography>
                        <Box sx={{ width: 300, height: 300, mt: -3 }}>
                            <Lottie animationData={PieChartAnimation} loop={true} />
                        </Box>
                    </Stack>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card sx={{ padding: 2 }}>
                    <Typography variant="h4" textAlign={"center"} mb={2}>Categories</Typography>

                    <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="h6" textAlign={"center"} mt={3}><i>No data available</i></Typography>
                        <Box sx={{ width: 300, height: 300, mb:-5 }}>
                            <Lottie animationData={BarChartAnimation} loop={true} />
                        </Box>
                    </Stack>
                </Card>
            </Grid>
        </Grid>
    </DashboardLayout>;


    // "canceled" ve "expired" statülerini filtrele
    const filteredStatusData = Object.keys((statusData as any).data.statusStatistics)
        .filter((key) => key !== 'canceled' && key !== 'expired')
        .map((key) => ({
            name: key,
            value: (statusData as any).data.statusStatistics[key],
        }));

    const letsTitleBarData = Object.keys((letsTitleData as any).data.letsTitleStatistics).map((key) => ({
        name: key === "letsConnect" ? "Let's Connect" :
            key === "letsLearn" ? "Let's Learn" :
                key === "letsMove" ? "Let's Move" :
                    key === "letsRelax" ? "Let's Relax" :
                        key === "letsTry" ? "Let's Try" : key,
        value: (letsTitleData as any).data.letsTitleStatistics[key],
    }));




    return (
        <DashboardLayout>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h1">Statistics</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{ padding: 2 }}>
                        <Typography variant="h4" textAlign={"center"}>Activities</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={filteredStatusData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {filteredStatusData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{ padding: 2 }}>
                        <Typography variant="h4" textAlign={"center"} mb={2}>Categories</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                                data={letsTitleBarData}
                                margin={{
                                    top: 5, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="value" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default Statistics;
