

import {
    IBaseGraphqlError,
    IGraphqlVariables,
} from "corede-common";
import { print } from "graphql"
import {
    IActivitiesInput, IActivitiesResult, adminActivitiesQuery, IAdminActivitiesResponse,
    IActivityInput, IActivityResult, adminActivityQuery, IAdminActivityResponse,
    userActivitiesQuery, IUserActivitiesResponse,
    userActivityQuery, IUserActivityResponse,
    IAppliedActivitiesResponse, appliedActivitiesQuery,
    ILetsTitleStatisticsResponse, letsTitleStatisticsQuery, ILetsTitleStatisticsInput,
    IStatusStatisticsResponse, statusStatisticsQuery, IStatusStatisticsInput,
    IUserStatisticsResponse, userStatisticsQuery, IUserStatisticsInput,

    ICreateActivityInput, ICreateActivityResult, createActivityQuery,
    IUpdateActivitySessionInformationInput, IUpdateActivitySessionInformationResult, updateActivitySessionInformationQuery, IUpdateActivitySessionInformationFilterInput,
    IUpdateActivityGeneralInformationFilterInput, IUpdateActivityGeneralInformationResult, updateActivityGeneralInformationQuery, IUpdateActivityGeneralInformationInput,
    IAddToBlacklistInput, IAddToBlacklistResult, addToBlacklistQuery,
    IJoinActivityFilterInput, IJoinActivityResult, joinActivityQuery,
    ILeaveActivityFilterInput, ILeaveActivityResult, leaveActivityQuery,
    IRemoveFromActivityFilterInput, IRemoveFromActivityInput, IRemoveFromActivityResult, removeFromActivityQuery,
    IRemoveFromBlacklistInput, IRemoveFromBlacklistResult, removeFromBlacklistQuery,
    ISetActivityStatusFilterInput, ISetActivityStatusInput, ISetActivityStatusResult, SetActivityStatusQuery,
    IAddToActivityFilterInput, IAddToActivityInput, IAddToActivityResult, addToActivityQuery,
    IAddToActivityAsMainParticipantFilterInput, IAddToActivityAsMainParticipantInput, IAddToActivityAsMainParticipantResult, addToActivityAsMainParticipantQuery,
    IUpdateActivityParticipatedParticipantsFilterInput, IUpdateActivityParticipatedParticipantsInput, IUpdateActivityParticipatedParticipantsResult, updateActivityParticipatedParticipantsQuery,
} from "corede-common-umbrella";
import { commonApi } from "context/commonApi";

const dashboardiApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({

        // queries

        adminActivities: builder.query<IActivitiesResult, IActivitiesInput>({
            query: (variables: IActivitiesInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(adminActivitiesQuery),
                        variables: {
                            input: {
                                pagination: {
                                    page: variables?.pagination?.page,
                                    pageSize: variables?.pagination?.pageSize,
                                    sort: variables?.pagination?.sort,
                                },
                                filter: {
                                    letsTitle: variables?.filter?.letsTitle
                                }
                            }
                        }
                    })
                })
            },
            transformResponse: (response: IAdminActivitiesResponse): IActivitiesResult => {
                return response.data.admin_activities
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            providesTags: ['activities']
        }),

        adminActivity: builder.query<IActivityResult, IActivityInput>({
            query: (variables: IActivityInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(adminActivityQuery),
                        variables: {
                            input: {
                                id: variables.id
                            }
                        }
                    })
                })
            },
            transformResponse: (response: IAdminActivityResponse): IActivityResult => {
                return response.data.admin_activity
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            providesTags: ["activity"]
        }),

        userActivities: builder.query<IActivitiesResult, IActivitiesInput>({
            query: (variables: IActivitiesInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(userActivitiesQuery),
                        variables: {
                            input: {
                                pagination: {
                                    page: variables?.pagination?.page,
                                    pageSize: variables?.pagination?.pageSize,
                                    sort: variables?.pagination?.sort,
                                },
                                filter: {
                                    letsTitle: variables?.filter?.letsTitle,
                                    statuses: variables?.filter?.statuses,
                                    sessionAllowedDepartments: variables?.filter?.sessionAllowedDepartments
                                }
                            }
                        }
                    })
                })
            },
            transformResponse: (response: IUserActivitiesResponse): IActivitiesResult => {
                return response.data.user_activities
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            providesTags: ['activities']
        }),

        userActivity: builder.query<IActivityResult, IActivityInput>({
            query: (variables: IActivityInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(userActivityQuery),
                        variables: {
                            input: {
                                id: variables.id
                            }
                        }
                    })
                })
            },
            transformResponse: (response: IUserActivityResponse): IActivityResult => {
                return response.data.user_activity
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            providesTags: ["activity"]
        }),

        appliedActivities: builder.query<IActivitiesResult, IActivitiesInput>({
            query: (variables: IActivitiesInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(appliedActivitiesQuery),
                        variables: {
                            input: {
                                pagination: {
                                    page: variables?.pagination?.page,
                                    pageSize: variables?.pagination?.pageSize,
                                    sort: variables?.pagination?.sort,
                                },
                                filter: {
                                    letsTitle: variables?.filter?.letsTitle
                                }
                            }
                        }
                    })
                })
            },
            transformResponse: (response: IAppliedActivitiesResponse): IActivitiesResult => {
                return response.data.appliedActivities
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            providesTags: ['activities']
        }),

        letsTitleStatistics: builder.query<ILetsTitleStatisticsResponse, ILetsTitleStatisticsInput>({
            query: (variables: ILetsTitleStatisticsInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(letsTitleStatisticsQuery),
                        variables: {
                            input: {
                                filter: variables.filter
                            }
                        }
                    })
                })
            },
            transformResponse: (response: ILetsTitleStatisticsResponse) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
        }),

        statusStatistics: builder.query<IStatusStatisticsResponse, IStatusStatisticsInput>({
            query: (variables: IStatusStatisticsInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(statusStatisticsQuery),
                        variables: {
                            input: {
                                filter: variables.filter
                            }
                        }
                    })
                })
            },
            transformResponse: (response: IStatusStatisticsResponse) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
        }),

        userStatistics: builder.query<IUserStatisticsResponse, IUserStatisticsInput>({
            query: (variables: IUserStatisticsInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(userStatisticsQuery),
                        variables: {
                            input: {
                                filter: variables.filter
                            }
                        }
                    })
                })
            },
            transformResponse: (response: IUserStatisticsResponse) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
        }),







        // mutations

        createActivity: builder.mutation<ICreateActivityResult, ICreateActivityInput>({
            query: (variables: ICreateActivityInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(createActivityQuery),
                        variables: {
                            input: {
                                title: variables.title,
                                sessions: variables.sessions,
                                mainQuota: variables.mainQuota,
                                reserveQuota: variables.reserveQuota,
                                categories: variables.categories,
                                date: variables.date,
                                reservationCloseDate: variables.reservationCloseDate,
                                location: variables.location,
                                details: variables.details,
                                letsTitle: variables.letsTitle
                            },
                        },
                    })
                })
            },
            transformResponse: (response: ICreateActivityResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity']
        }),

        updateActivitySession: builder.mutation<IUpdateActivitySessionInformationResult, IUpdateActivitySessionInformationFilterInput & IUpdateActivitySessionInformationInput>({
            query: (variables: IUpdateActivitySessionInformationFilterInput & IUpdateActivitySessionInformationInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(updateActivitySessionInformationQuery),
                        variables: {
                            filter: {
                                _id: variables._id,
                            },
                            input: {
                                sessionName: variables.sessionName,
                                sessionTimeInterval: variables.sessionTimeInterval,
                                sessionAllowedDepartments: variables.sessionAllowedDepartments,
                            },
                        },
                    })
                })
            },
            transformResponse: (response: IUpdateActivitySessionInformationResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity']
        }),

        updateActivityGeneral: builder.mutation<IUpdateActivityGeneralInformationResult, IUpdateActivityGeneralInformationFilterInput & IUpdateActivityGeneralInformationInput>({
            query: (variables: IUpdateActivityGeneralInformationFilterInput & IUpdateActivityGeneralInformationInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(updateActivityGeneralInformationQuery),
                        variables: {
                            filter: {
                                activityId: variables.activityId,
                            },
                            input: {
                                title: variables.title,
                                mainQuota: variables.mainQuota,
                                reserveQuota: variables.reserveQuota,
                                categories: variables.categories,
                                date: variables.date,
                                reservationCloseDate: variables.reservationCloseDate,
                                location: variables.location,
                                details: variables.details,
                                letsTitle: variables.letsTitle
                            },
                        },
                    })
                })
            },
            transformResponse: (response: IUpdateActivityGeneralInformationResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity']
        }),

        addToBlacklist: builder.mutation<IAddToBlacklistResult, IAddToBlacklistInput>({
            query: (variables: IAddToBlacklistInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(addToBlacklistQuery),
                        variables: {
                            input: {
                                userId: variables.userId
                            },
                        },
                    })
                })
            },
            transformResponse: (response: IAddToBlacklistResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity', 'user', 'users']
        }),

        joinActivity: builder.mutation<IJoinActivityResult, IJoinActivityFilterInput>({
            query: (variables: IJoinActivityFilterInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(joinActivityQuery),
                        variables: {
                            filter: {
                                _id: variables._id
                            },
                        },
                    })
                })
            },
            transformResponse: (response: IJoinActivityResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity']
        }),

        leaveActivity: builder.mutation<ILeaveActivityResult, ILeaveActivityFilterInput>({
            query: (variables: ILeaveActivityFilterInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(leaveActivityQuery),
                        variables: {
                            filter: {
                                _id: variables._id
                            },
                        },
                    })
                })
            },
            transformResponse: (response: ILeaveActivityResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity']
        }),

        removeFromActivity: builder.mutation<IRemoveFromActivityResult, IRemoveFromActivityFilterInput & IRemoveFromActivityInput>({
            query: (variables: IRemoveFromActivityFilterInput & IRemoveFromActivityInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(removeFromActivityQuery),
                        variables: {
                            filter: {
                                _id: variables._id
                            },
                            input: {
                                userId: variables.userId
                            },
                        },
                    })
                })
            },
            transformResponse: (response: IRemoveFromActivityResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity']
        }),

        removeFromBlacklist: builder.mutation<IRemoveFromBlacklistResult, IRemoveFromBlacklistInput>({
            query: (variables: IRemoveFromBlacklistInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(removeFromBlacklistQuery),
                        variables: {
                            input: {
                                userId: variables.userId
                            },
                        },
                    })
                })
            },
            transformResponse: (response: IRemoveFromBlacklistResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity', 'user', 'users']
        }),

        setActivityStatus: builder.mutation<ISetActivityStatusResult, ISetActivityStatusFilterInput & ISetActivityStatusInput>({
            query: (variables: ISetActivityStatusFilterInput & ISetActivityStatusInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(SetActivityStatusQuery),
                        variables: {
                            filter: {
                                activitySessionId: variables.activitySessionId
                            },
                            input: {
                                status: variables.status
                            },
                        },
                    })
                })
            },
            transformResponse: (response: ISetActivityStatusResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity']
        }),

        addToActivity: builder.mutation<IAddToActivityResult, IAddToActivityInput & IAddToActivityFilterInput>({
            query: (variables: IAddToActivityInput & IAddToActivityFilterInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(addToActivityQuery),
                        variables: {
                            filter: {
                                activityId: variables.activityId
                            },
                            input: {
                                userId: variables.userId
                            },
                        },
                    })
                })
            },
            transformResponse: (response: IAddToActivityResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity']
        }),

        addToActivityAsMainParticipant: builder.mutation<IAddToActivityAsMainParticipantResult, IAddToActivityAsMainParticipantInput & IAddToActivityAsMainParticipantFilterInput>({
            query: (variables: IAddToActivityAsMainParticipantInput & IAddToActivityAsMainParticipantFilterInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(addToActivityAsMainParticipantQuery),
                        variables: {
                            filter: {
                                activityId: variables.activityId
                            },
                            input: {
                                userId: variables.userId
                            },
                        },
                    })
                })
            },
            transformResponse: (response: IAddToActivityAsMainParticipantResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity']
        }),

        updateActivityParticipatedParticipants: builder.mutation<IUpdateActivityParticipatedParticipantsResult, IUpdateActivityParticipatedParticipantsInput & IUpdateActivityParticipatedParticipantsFilterInput>({
            query: (variables: IUpdateActivityParticipatedParticipantsInput & IUpdateActivityParticipatedParticipantsFilterInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(updateActivityParticipatedParticipantsQuery),
                        variables: {
                            filter: {
                                activityId: variables.activityId
                            },
                            input: {
                                userIds: variables.userIds
                            },
                        },
                    })
                })
            },
            transformResponse: (response: IUpdateActivityParticipatedParticipantsResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ['activities', 'activity']
        })


    })
})


export const {
    useAdminActivitiesQuery, useLazyAdminActivitiesQuery,
    useAdminActivityQuery, useLazyAdminActivityQuery,
    useUserActivitiesQuery, useLazyUserActivitiesQuery,
    useUserActivityQuery, useLazyUserActivityQuery,
    useAppliedActivitiesQuery, useLazyAppliedActivitiesQuery,
    useLetsTitleStatisticsQuery, useLazyLetsTitleStatisticsQuery,
    useStatusStatisticsQuery, useLazyStatusStatisticsQuery,
    useUserStatisticsQuery, useLazyUserStatisticsQuery,

    useCreateActivityMutation,
    useUpdateActivitySessionMutation,
    useUpdateActivityGeneralMutation,
    useAddToBlacklistMutation,
    useJoinActivityMutation,
    useLeaveActivityMutation,
    useRemoveFromActivityMutation,
    useRemoveFromBlacklistMutation,
    useSetActivityStatusMutation,
    useAddToActivityMutation,
    useAddToActivityAsMainParticipantMutation,
    useUpdateActivityParticipatedParticipantsMutation
} = dashboardiApi