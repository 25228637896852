import { ChangeCircleOutlined, Edit, RemoveRedEye } from '@mui/icons-material'
import { Box, Button, Card, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Menu, MenuItem, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout'
import { routes } from 'apps/dashboard/routes/routes'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAdminActivitiesQuery, useSetActivityStatusMutation } from 'apps/dashboard/context'
import moment from 'moment'
import { activityStatus, categories } from 'utils';
import { useFormik } from 'formik';
import { TextField } from 'components'
import Label from 'minimal/components/label'
import { ActivityStatus } from 'corede-common-umbrella'
import { enqueueSnackbar } from 'notistack'

const Activities = () => {

    const navigate = useNavigate()
    const [selectedActivity, setSelectedActivity] = useState<any>(null)
    const [selectedActivityUpdate, setSelectedActivityUpdate] = useState<any>(null)
    const [variables, setVariables] = useState<any>({
        pagination: { page: 1, pageSize: 10, sort: '' },
        filter: { letsTitle: '' }
    })
    const { data, error, isLoading } = useAdminActivitiesQuery(variables)
    const [setActivityStatus, { isLoading: idLoadingUpdate, data: dataUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate }] = useSetActivityStatusMutation()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handeleUpdateSession = () => {
        navigate(routes.admin.updateActivitySession.replace(':id', selectedActivityUpdate?._id + ''))
    }

    const handleUpdateGeneralActivity = () => {
        navigate(routes.admin.updateActivity.replace(':id', selectedActivityUpdate?._id + ''))
    }

    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm } = useFormik({
        initialValues: {
            status: '' // Set a default value for status
        },
        onSubmit: values => {
            setActivityStatus({ status: values.status as ActivityStatus, activitySessionId: selectedActivity?._id })
            console.log(values, 'valuessssss')
        }
    })

    useEffect(() => {
        if (selectedActivity?.status) {
            console.log(selectedActivity?.status, 'selectedActivity?.status')
            setFieldValue('status', selectedActivity?.status)
        }
    }, [selectedActivity])

    useEffect(() => {
        if (isSuccessUpdate) {
            enqueueSnackbar('Activity status updated successfully', { variant: 'success' })
            setSelectedActivity(null)
            resetForm()
        }
    }, [isSuccessUpdate])

    useEffect(() => {
        if (isErrorUpdate) {
            enqueueSnackbar((error as any)?.error?.message?.en || 'Activity status update failed', { variant: 'error' })
        }
    }, [isErrorUpdate])

    return <DashboardLayout>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h1'>Activities </Typography>
                    <Button variant="contained" color="secondary"
                        onClick={() => {
                            navigate(routes.admin.createActivity)
                        }}>Create Activity</Button>
                </Stack>
            </Grid>
            <Grid item xs={12} >
                <Box sx={{ py: 2, px: 4, mb: 5, bgcolor: 'primary.lighter', borderRadius: 2 }}>
                    <Typography variant='body1'>
                        This page is for managing activities. You can create, edit, delete and view details of activities. You can also see the list of participants who have registered for the activities.
                    </Typography>
                </Box>

                <Stack direction="row" gap={2} mb={2} alignItems={"center"} justifyContent={"center"} flexWrap="wrap">
                    <Chip label={"All"} variant={variables.filter.letsTitle === '' ? 'filled' : 'outlined'}
                        color='primary' sx={{ height: 40 }}
                        onClick={() => { setVariables({ ...variables, filter: { letsTitle: '' } }) }}
                    />
                    {categories.map((item: any, index: any) => (
                        <Chip key={index} label={item.label} variant={variables.filter.letsTitle === item.value ? 'filled' : 'outlined'}
                            color='primary' sx={{ height: 40 }}
                            onClick={() => { setVariables({ ...variables, filter: { letsTitle: item.value } }) }} />
                    ))}
                </Stack>

                <Card >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>

                                    <TableCell>#</TableCell>
                                    <TableCell>Title / Category</TableCell>
                                    <TableCell align='center'>Quota</TableCell>
                                    <TableCell align='center'>Date</TableCell>
                                    <TableCell align='center'>Session</TableCell>
                                    <TableCell align='center'>Status</TableCell>
                                    <TableCell align='center'>Detail / Edit / Change Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(data && data.data?.length > 0) ?
                                    data?.data?.map((item: any, index: any) => (
                                        <TableRow key={index}>
                                            <TableCell>{(variables.pagination.page - 1) * variables.pagination.pageSize + index + 1}</TableCell>
                                            <TableCell>
                                                <Typography >{item.title}</Typography>
                                                <Typography fontWeight={"bold"}>{categories.find((category: any) => category.value === item.letsTitle)?.label}</Typography>
                                            </TableCell>
                                            <TableCell align='center'>{item.mainQuota}</TableCell>
                                            <TableCell align='center'>{moment(item.date).format('DD MMM YYYY')}</TableCell>
                                            <TableCell align='center'>
                                                <Typography fontSize={"small"} fontWeight={"bold"} >{item.sessionName}</Typography>
                                                <Typography fontSize={"small"}>{item.sessionAllowedDepartments.join(', ')}</Typography>
                                                <Typography fontSize={"small"}>{item.sessionTimeInterval}</Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Typography border={"1px solid #ccc"} p={0.5} borderRadius={1} fontSize={"small"} fontWeight={"bold"}>{item.status?.split('')[0].toUpperCase() + item.status?.slice(1)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" spacing={1} justifyContent={"center"} alignItems={"center"}>
                                                    <Button variant="outlined" sx={{ height: 40 }} color="secondary"
                                                        onClick={() => {
                                                            navigate(routes.admin.activityDetail.replace(':id', item._id + ''))
                                                        }}>
                                                        <RemoveRedEye sx={{ fontSize: '18px' }} />
                                                    </Button>
                                                    <Button
                                                        variant="outlined" sx={{ height: 40 }} color="primary"
                                                        id="basic-button"
                                                        aria-controls={open ? 'basic-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={e => {
                                                            handleClick(e)
                                                            setSelectedActivityUpdate(item)
                                                        }}
                                                    >
                                                        <Edit sx={{ fontSize: '18px' }} />
                                                    </Button>
                                                    <Button variant="outlined" sx={{ height: 40 }} color="info"
                                                        onClick={() => {
                                                            setSelectedActivity(item)
                                                        }}>
                                                        <ChangeCircleOutlined sx={{ fontSize: '24px' }} />
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>

                                    ))
                                    :
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                            <Typography align="center"> There is no activity</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Stack direction="row" justifyContent="center" mt={2} mb={2}>
                        <Pagination
                            count={Math.ceil((data as any)?.count / variables.pagination.pageSize)}
                            page={variables.pagination.page}
                            onChange={(e, page) => {
                                setVariables({
                                    ...variables,
                                    pagination: {
                                        ...variables.pagination,
                                        page: page
                                    }
                                })
                            }}
                            color="primary"
                        />
                    </Stack>
                </Card>
            </Grid>
        </Grid >

        <Dialog open={selectedActivity !== null} onClose={() => setSelectedActivity(null)}
            sx={{
                '& .MuiDialog-paper': {
                    padding: 1,
                    borderRadius: 2
                }
            }}>
            <DialogTitle>
                Change Activity Status
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Typography textAlign={"center"} variant={"body1"} mb={2}>
                    What would you like to do to the status <br />of the activity named <b>{selectedActivity?.title}</b>?
                </Typography>
                {values?.status && <TextField
                    label="Status"
                    name="status"
                    values={values}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    select
                    datas={activityStatus}
                />}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSelectedActivity(null)}>Cancel</Button>
                <Button variant='contained' color="secondary" disabled={!values.status} onClick={() => handleSubmit()}>Change Status</Button>
            </DialogActions>
        </Dialog>

        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={handeleUpdateSession} sx={{ fontSize: 'small', fontWeight: 'bold' }}>Update Session Activity</MenuItem>
            <MenuItem onClick={handleUpdateGeneralActivity} sx={{ fontSize: 'small', fontWeight: 'bold' }}>Update General Activity</MenuItem>
        </Menu>
    </DashboardLayout >
}

export default Activities