

import {
    IBaseGraphqlError,
    IUserProfileImagePresignedUrlResponse, IUserProfileImagePresignedUrlResult, userProfileImagePresignedUrlQuery,
} from "corede-common";
import { print } from "graphql"
import { commonApi } from "context/commonApi";


const profileApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({

        // queries

        profileImagePresignedUrl: builder.query<IUserProfileImagePresignedUrlResult, any>({
            query: () => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(userProfileImagePresignedUrlQuery),
                    })
                })
            },
            transformResponse: (response: IUserProfileImagePresignedUrlResponse): IUserProfileImagePresignedUrlResult => {
                console.log(response)
                return response.data.userProfileImagePresignedUrl
            },
            transformErrorResponse: (response: IBaseGraphqlError): IBaseGraphqlError => {
                return response
            },
            providesTags: []
        }),




    })
})


export const {
    useProfileImagePresignedUrlQuery, useLazyProfileImagePresignedUrlQuery,

} = profileApi