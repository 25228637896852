import {  ArrowBackIos, CalendarMonth, CategoryOutlined, Countertops, Description, Group, LocationCity, LockClock, StarOutlineSharp, TextSnippet } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Typography } from '@mui/material'
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout'
import { useParams, useNavigate } from 'react-router-dom';
import { useUserActivityQuery, useLeaveActivityMutation, useJoinActivityMutation } from 'apps/dashboard/context';
import moment from 'moment';
import parse from 'html-react-parser';
import Lottie from 'lottie-react';
import LetsRelax from 'assets/animations/letsrelax.json';
import LetsTry from 'assets/animations/letstry.json';
import LetsLearn from 'assets/animations/letslearn.json';
import LetsMove from 'assets/animations/letsmove.json';
import LetsConnect from 'assets/animations/letsconnect.json';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { categories } from 'utils';



const ActivityDetail = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const [selectedActivity, setSelectedActivity] = useState<any>(null)
    const { data, error, isLoading, refetch } = useUserActivityQuery({ id: id ?? '' })
    const [joinActivity, { data: joinData, error: joinError, isLoading: joinLoading }] = useJoinActivityMutation()
    const [leaveActivity, { data: leaveData, error: leaveError, isLoading: leaveLoading }] = useLeaveActivityMutation()


    console.log(data)

    const handleJoin = () => {
        joinActivity({ _id: selectedActivity._id })
    }

    const handleLeave = () => {
        leaveActivity({ _id: selectedActivity._id })
    }

    useEffect(() => {
        if (joinData) {
            enqueueSnackbar('Activity joined successfully', { variant: 'success' })
            setSelectedActivity(null)
            refetch()
        }
    }, [joinData])

    useEffect(() => {
        if (joinError) {
            enqueueSnackbar((joinError as any).error.message.en, { variant: 'error' })
        }
    }, [joinError])

    useEffect(() => {
        if (leaveData) {
            enqueueSnackbar('Activity left successfully', { variant: 'success' })
            setSelectedActivity(null)
            refetch()
        }
    }, [leaveData])

    useEffect(() => {
        if (leaveError) {
            enqueueSnackbar((leaveError as any).error.message.en, { variant: 'error' })
        }
    }, [leaveError])

    return <DashboardLayout>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <ArrowBackIos onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }} />
                        <h1>Activity Detail</h1>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant={!data?.applied ? 'contained' : 'outlined'}
                            color={!data?.applied  ? 'secondary' : 'error'}
                            onClick={() => {
                                setSelectedActivity(data)
                            }}>
                            {!data?.applied  ? "Join Activity" : "Leave Activity"}
                        </Button>
                    </Stack>

                </Stack>
            </Grid>
            <Grid item xs={12} >
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Card sx={{ position: 'relative' }}>
                            <Stack spacing={1} p={3}>
                                <Typography variant="h3">{data?.title}</Typography>
                                <Divider sx={{ width: '50%' }} />
                                <Box sx={{ height: 10 }} />
                                <Stack direction={{ xs: 'column', md: 'row-reverse' }} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack spacing={1} p={3} direction={'column'} alignItems={'center'} justifyContent={'center'}>
                                        <Box sx={{ width: 200 }}>
                                            {data?.letsTitle === "letsRelax" && <Lottie animationData={LetsRelax} loop={true} />}
                                            {data?.letsTitle === "letsTry" && <Lottie animationData={LetsTry} loop={true} />}
                                            {data?.letsTitle === "letsLearn" && <Lottie animationData={LetsLearn} loop={true} />}
                                            {data?.letsTitle === "letsMove" && <Lottie animationData={LetsMove} loop={true} />}
                                            {data?.letsTitle === "letsConnect" && <Lottie animationData={LetsConnect} loop={true} />}
                                        </Box>
                                    </Stack>
                                    <Stack spacing={1} p={3}>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <CategoryOutlined />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Category: </Typography>
                                            <Typography variant="subtitle1">{categories.find((category: any) => category.value === data?.letsTitle)?.label}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <LocationCity />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Location: </Typography>
                                            <Typography variant="subtitle1">{data?.location}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <TextSnippet />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Session Name: </Typography>
                                            <Typography variant="subtitle1">{data?.sessionName}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <Group />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Session Allowed Departments: </Typography>
                                            <Typography variant="subtitle1">{data?.sessionAllowedDepartments.join(', ')}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <LockClock />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Session Time Interval: </Typography>
                                            <Typography variant="subtitle1">{data?.sessionTimeInterval}</Typography>
                                        </Stack>
                                        {/* <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <Countertops />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Main Quota: </Typography>
                                            <Typography variant="subtitle1">{data?.mainQuota}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <Countertops />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Reserve Quota: </Typography>
                                            <Typography variant="subtitle1">{data?.reserveQuota}</Typography>
                                        </Stack> */}
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <CalendarMonth />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Activity Date: </Typography>
                                            <Typography variant="subtitle1">{moment(data?.date).format('DD MMM YYYY')}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <CalendarMonth />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Close Date: </Typography>
                                            <Typography variant="subtitle1">{moment(data?.reservationCloseDate).format('DD MMM YYYY')}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <StarOutlineSharp />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Status: </Typography>
                                            <Typography variant="subtitle1">{data?.status}</Typography>
                                        </Stack>
                                    </Stack>

                                </Stack>
                                <Stack direction="row" spacing={2} alignItems={'center'}>
                                    <Description />
                                    <Typography variant="subtitle1" fontWeight={"bold"}>Details: </Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems={'center'} sx={{ pl: 5 }}>
                                    <Typography variant="subtitle1">{parse(data?.details ?? '<p></p>')}</Typography>
                                </Stack>
                            </Stack>

                        </Card>
                    </Grid>



                </Grid>
            </Grid>
        </Grid>

        <Dialog open={selectedActivity !== null} onClose={() => setSelectedActivity(null)}
            sx={{
                '& .MuiDialog-paper': {
                    padding: 1,
                    borderRadius: 2
                }
            }}>
            <DialogTitle>
                {!selectedActivity?.applied ? "Join Activity" : "Leave Activity"}
                <Divider />
            </DialogTitle>
            <DialogContent sx={{ minWidth: { xs: '100%', md: 400 } }}>

                <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                    <Box>
                        {!selectedActivity?.applied ? <Typography>
                            Are you sure you want to join <b>{selectedActivity?.title}</b> ? <br /><br />
                        </Typography> : <Typography>
                            Are you sure you want to leave <b>{selectedActivity?.title}</b> ? <br /><br />
                        </Typography>}
                        <Typography>
                            <b>Location:</b> {selectedActivity?.location}
                        </Typography>
                        <Typography>
                            <b>Session:</b> {selectedActivity?.sessionName}
                        </Typography>
                        <Typography>
                            <b>Time:</b> {selectedActivity?.sessionTimeInterval}
                        </Typography>
                    </Box>
                    <Box sx={{ width: 300 }}>
                        {selectedActivity?.letsTitle === "letsRelax" && <Lottie animationData={LetsRelax} loop={true} />}
                        {selectedActivity?.letsTitle === "letsTry" && <Lottie animationData={LetsTry} loop={true} />}
                        {selectedActivity?.letsTitle === "letsLearn" && <Lottie animationData={LetsLearn} loop={true} />}
                        {selectedActivity?.letsTitle === "letsMove" && <Lottie animationData={LetsMove} loop={true} />}
                        {selectedActivity?.letsTitle === "letsConnect" && <Lottie animationData={LetsConnect} loop={true} />}
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSelectedActivity(null)}>Cancel</Button>
                {!selectedActivity?.applied ? <Button variant='contained' color="secondary"
                    disabled={joinLoading}
                    onClick={() => {
                        handleJoin()
                    }}
                >{joinLoading ? <CircularProgress size="1rem" /> : "Join Activity"}</Button> :
                    <Button variant='contained' color="error"
                        disabled={leaveLoading}
                        onClick={() => {
                            handleLeave()
                        }}
                    >{leaveLoading ? <CircularProgress size="1rem" /> : "Leave Activity"}</Button>}
            </DialogActions>
        </Dialog>
    </DashboardLayout >
}

export default ActivityDetail
