import { Route } from 'react-router-dom'

import { CreatePassword, ForgotPassword, Login, ResetPassword } from '../pages';
import { routes } from './routes';



export const AuthRouter = () => {
    return (
        <>
            <Route path="/" element={<Login />} />
            <Route path={routes.auth.resetPassword} element={<ResetPassword />} />
            <Route path={routes.auth.forgotPassword} element={<ForgotPassword />} />
            <Route path={routes.auth.createPassword} element={<CreatePassword />} />

            <Route path="*" element={<Login />} />
        </>
    )
}
