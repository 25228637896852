import { DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/tr';

dayjs.extend(utc);
dayjs.extend(timezone);


dayjs.locale('tr');
dayjs.tz.setDefault('Europe/Istanbul');

interface IDateField {
    values: any
    minDate: any
    maxDate: any
    name: string
    setFieldValue: (field: string, value: any) => void
    label: string
}

export const DateField = ({ values, label, minDate, maxDate, name, setFieldValue }: IDateField) => {

    return (
        <DatePicker
            sx={{ width: '100%' }}
            format='DD/MM/YYYY'
            minDate={dayjs(minDate).tz('Europe/Istanbul')}
            maxDate={dayjs(maxDate).tz('Europe/Istanbul')}
            label={label}
            value={values[name] ? dayjs(values[name]).tz('Europe/Istanbul', true) : null}
            onChange={(date) => {
                if (date) {
                    const adjustedDate = name === "reservationCloseDate" ? dayjs(date).add(3, 'hour') : dayjs(date).add(4, 'hour');
                    setFieldValue(name, adjustedDate);
                } else {
                    setFieldValue(name, null);
                }
            }}
            onError={(error, value) => {
                console.log(error, value);
            }}
        />
    );
}
