import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppBar, Box, Container, Drawer, Grid, IconButton, Menu, Stack, Toolbar } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'context'
import { useMediaQuery } from '@mui/material';
import NavDefault from "components/navigations/NavDefault";
import { routes } from 'apps/dashboard/routes/routes';
import { useLocales } from 'minimal/locales';
import { Badge } from '@mui/material';
import { removeUser } from 'apps/auth/context';
import { CalendarMonth, Dashboard, Drafts, Event, Menu as MenuIcon, People } from '@mui/icons-material';
import Logo from 'assets/hapag-logo.png';

const DashboardLayout = ({ children }: any) => {

    const { t } = useLocales();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const { accessToken, role } = useAppSelector(state => state.auth)
    const [mobileDrawer, setMobileDrawer] = useState(false)
    const [isNavbarMini, setIsNavbarMini] = useState(false);

    const toggleNavbarSize = () => {
        setIsNavbarMini(!isNavbarMini);
    };

    const open = Boolean(anchorEl);


    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleNavigation = (path = "/") => {
        navigate(path);
        handleClose();
        setAnchorEl(null);
    }


    const handleAccount = () => {
        role === "admin" && handleNavigation(routes.admin.profile);
        role === "user" && handleNavigation(routes.user.profile);
    }


    const handleLogout = () => {
        dispatch(removeUser())
        document.location.reload();
    }

    const handleSettings = () => {
        handleNavigation('/settings');
    }


    // const menuForAdmin = [
    //     {
    //         title: 'Dashboard',
    //         icon: <DashboardIcon />,
    //         link: routes.admin.dashboard
    //     },
    //     {
    //         title: 'Activities',
    //         icon: <EventIcon />,
    //         link: routes.admin.activities
    //     },
    //     {
    //         title: 'Users',
    //         icon: <PeopleIcon />,
    //         link: routes.admin.users
    //     },
    //     {
    //         title: 'Statistics',
    //         icon: <DraftsIcon />,
    //         link: routes.admin.statistics
    //     },
    // ]


    const renderDashboardMenu = () => {

        switch (role) {
            case "admin":
                return ([
                    {
                        items: [
                            { title: t('Dashboard'), path: routes.admin.dashboard, icon: <Dashboard /> },
                            { title: t('Activities'), path: routes.admin.activities, icon: <Event /> },
                            { title: t('Users'), path: routes.admin.users, icon: <People /> },
                            { title: t('Statistics'), path: routes.admin.statistics, icon: <Drafts /> },
                        ]
                    },
                ])
            case "user":
                return ([
                    {
                        items: [
                            { title: t('Dashboard'), path: routes.user.dashboard, icon: <Dashboard /> },
                            { title: t('Activities'), path: routes.user.activities, icon: <Event /> },
                            { title: t('Applications'), path: routes.user.applications, icon: <People /> },
                            { title: t('Calendar'), path: routes.user.calendar, icon: <CalendarMonth /> },
                            { title: t('Statistics'), path: routes.user.statistics, icon: <Drafts /> },
                        ]
                    },
                ])

            default:
                return null
        }
    }

    return (
        <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
                height: '100%',
            }}
        >

            {/* ******************   menü ********************** */}
            <Box sx={{ width: { xs: '100%', md: '400px' } }}>
                {/* ******************   desktop navbar ********************** */}
                <Box sx={{ height: '100%', position: 'fixed', display: { xs: 'none', md: 'flex' } }}>
                    <NavDefault
                        open={open}
                        anchorEl={anchorEl}
                        navData={renderDashboardMenu()}
                        handleClose={handleClose}
                        handleClick={handleClick}
                        handleLogout={handleLogout}
                        handleAccount={handleAccount}
                        handleSettings={handleSettings}
                        isNavbarMini={isNavbarMini}
                        toggleNavbarSize={toggleNavbarSize}
                    />
                </Box>



                {/* ******************   mobile navbar ********************** */}
                <AppBar position="relative" sx={{ display: { xs: 'block', md: 'none' }, boxShadow: '1px 1px 5px 0px #ccc', py: 1, backgroundColor: '#FFF' }}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            zIndex: 1,
                        }}
                    >

                        <IconButton
                            onClick={() => setMobileDrawer(true)}
                            size="large"
                            edge="start"
                            color="default"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                zIndex: -3,
                            }}
                        >
                            <img
                                width="180px"
                                src={Logo}
                                alt="logo"
                                style={{ zIndex: 2 }}
                            />
                        </Stack>

                        {/* <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <NotificationDrawer />
                        </Box> */}


                    </Toolbar>
                </AppBar>

                <Drawer
                    anchor="left"
                    open={mobileDrawer}
                    onClose={() => setMobileDrawer(false)}
                >
                    <NavDefault
                        open={open}
                        anchorEl={anchorEl}
                        navData={renderDashboardMenu()}
                        handleClose={handleClose}
                        handleClick={handleClick}
                        handleLogout={handleLogout}
                        handleAccount={handleAccount}
                        handleSettings={handleSettings}
                    />
                </Drawer>
            </Box>





            {/* ******************   main sections ********************** */}
            <Box sx={{ overflow: 'auto', width: '100%', minHeight: '100vh' }}>
                <Container sx={{ p: 3, ml: 0, py: 8, pt: { xs: 2, sm: 10 }, position: 'relative' }}>

                    {children}
                    <Box sx={{ display: { xs: 'block', md: 'none' }, position: 'absolute', bgcolor: 'primary.main', bottom: 0, left: 0, right: 0, textAlign: 'center', height: 10, width: '100%' }} />
                </Container>
            </Box>

        </Stack>
    )

}

export default DashboardLayout