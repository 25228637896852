import { Box, Button, Container, Grid, TextField, Typography, Link, CircularProgress } from "@mui/material";
import Logo from "assets/hapag-logo.png";
import AuthImage from "assets/auth-img.png";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { resetPasswordValidationSchema } from 'utils/validations';
import { routes } from "../routes/routes";
import { AuthLayout } from "../layouts";
import { useLocation, useNavigate } from "react-router-dom";
import { useResetPasswordAfterRequestMutation } from "../context";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";


const CreatePassword = () => {

  // linkte geçen token propunu almak için useLocation hookunu kullanıyoruz.

  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get('token');
  const [resetPasswordAfterRequest, { data, isLoading, isError, isSuccess, error }] = useResetPasswordAfterRequestMutation();


  const { values, handleSubmit, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    validationSchema: resetPasswordValidationSchema,
    initialValues: {
      password: '',
      passwordAgain: '',
    },
    onSubmit: (values) => {
      resetPasswordAfterRequest({
        password: values.password,
        token: token as string
      })
    }
  });

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Password has been reset successfully', { variant: 'success' })
      navigate(routes.auth.login)
    }
  }, [isSuccess, navigate])

  useEffect(() => {
    if (isError) {
      enqueueSnackbar((error as any)?.error?.message.en, { variant: 'error' })
    }
  }, [isError, error])



  return <AuthLayout>
    <Grid item xs={12} md={6} display={"flex"} height={"100vh"} justifyContent={'center'} alignItems={'center'}>

      <Box display={{ xs: 'flex', md: 'none' }} height={100} />
      <Box
        sx={{
          width: '90%',
          height: "80vh",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'center' },
          alignItems: 'center',
          gap: 5,
        }}
        px={{ xs: 1, md: 7 }}
      >
        <img src={Logo} alt="logo" width={220} />
        <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"}>
          <Typography fontSize={40} fontWeight={600} textAlign={"center"}>
            Reset Password
          </Typography>
          <Typography fontSize={20} fontWeight={400} textAlign={"center"}>
            Please enter your password
          </Typography>
        </Box>

        <Box display={"flex"} sx={{ width: '100%' }} flexDirection={"column"} gap={2} alignItems={"center"}>
          <TextField
            placeholder="Password"
            type="password"
            fullWidth
            value={values.password}
            onChange={(e) => setFieldValue('password', e.target.value)}
            error={errors.password ? true : false}
            helperText={errors.password}
          />
          <TextField
            placeholder="Password Again"
            type="password"
            fullWidth
            value={values.passwordAgain}
            onChange={(e) => setFieldValue('passwordAgain', e.target.value)}
            error={errors.passwordAgain ? true : false}
            helperText={errors.passwordAgain}
          />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={handleSubmit as any}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={"1rem"} /> :
              "Reset Password"}
          </Button>

          <Typography fontSize={15} fontWeight={500} >
            <Link href={routes.auth.login} color={"#33424F"} underline={"hover"}>
              Back to Login
            </Link>
          </Typography>

        </Box>
      </Box>
    </Grid>
  </AuthLayout>
}

export default CreatePassword;
