import { Box, Button, Card, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Pagination, Stack, Typography } from '@mui/material'
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout'
import { routes } from 'apps/dashboard/routes/routes'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserActivitiesQuery } from 'apps/dashboard/context'
import { categories } from 'utils';
import LetsRelax from 'assets/letsrelax.png'
import LetsTry from 'assets/letstry.png'
import LetsLearn from 'assets/letslearn.png'
import LetsMove from 'assets/letsmove.png'
import LetsConnect from 'assets/letsconnect.png'
import Lottie from 'lottie-react'
import LetsRelaxAnimation from 'assets/animations/letsrelax.json'
import LetsTryAnimation from 'assets/animations/letstry.json'
import LetsLearnAnimation from 'assets/animations/letslearn.json'
import LetsMoveAnimation from 'assets/animations/letsmove.json'
import LetsConnectAnimation from 'assets/animations/letsconnect.json'
import { useJoinActivityMutation, useLeaveActivityMutation } from 'apps/dashboard/context'
import { enqueueSnackbar } from 'notistack'
import { useUserQuery } from 'apps/auth/context'

const Activities = () => {

    const navigate = useNavigate()
    const [selectedActivity, setSelectedActivity] = useState<any>(null)
    const [variables, setVariables] = useState<any>({
        pagination: { page: 1, pageSize: 10, sort: '' },
        filter: {
            letsTitle: '',
            statuses: ['scheduled'],
            sessionAllowedDepartments: []
        }
    })
    const { data: userData } = useUserQuery({})
    const { data, error, isLoading } = useUserActivitiesQuery(variables)
    const [joinActivity, { data: joinData, error: joinError, isLoading: joinLoading }] = useJoinActivityMutation()
    const [leaveActivity, { data: leaveData, error: leaveError, isLoading: leaveLoading }] = useLeaveActivityMutation()

    const handleJoin = () => {
        joinActivity({ _id: selectedActivity._id })
    }

    const handleLeave = () => {
        leaveActivity({ _id: selectedActivity._id })
    }

    useEffect(() => {
        if (joinData) {
            enqueueSnackbar('Activity joined successfully', { variant: 'success' })
            setSelectedActivity(null)
        }
    }, [joinData])

    useEffect(() => {
        if (joinError) {
            enqueueSnackbar((joinError as any).error.message.en, { variant: 'error' })
        }
    }, [joinError])

    useEffect(() => {
        if (leaveData) {
            enqueueSnackbar('Activity left successfully', { variant: 'success' })
            setSelectedActivity(null)
        }
    }, [leaveData])

    useEffect(() => {
        if (leaveError) {
            enqueueSnackbar((leaveError as any).error.message.en, { variant: 'error' })
        }
    }, [leaveError])

    useEffect(() => {
        if(userData?.department){
            setVariables({ ...variables, filter: { ...variables.filter, sessionAllowedDepartments: [userData?.department] } })
        }
    },[userData])

    return <DashboardLayout>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h1'>Activities </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} >
                <Box sx={{ py: 2, px: 4, mb: 5, bgcolor: 'primary.lighter', borderRadius: 2 }}>
                    <Typography variant='body1'>
                        This page is for you to list events. You can view details of activities. You can also register for events.
                    </Typography>
                </Box>

                <Stack direction="row" gap={2} mb={2} alignItems={"center"} justifyContent={"center"} flexWrap="wrap">
                    <Chip label={"All"} variant={variables.filter.letsTitle === '' ? 'filled' : 'outlined'}
                        color='primary' sx={{ height: 40 }}
                        onClick={() => { setVariables({ ...variables, filter: { ...variables.filter, letsTitle: '' } }) }}
                    />
                    {categories.map((item: any, index: any) => (
                        <Chip key={index} label={item.label} variant={variables.filter.letsTitle === item.value ? 'filled' : 'outlined'}
                            color='primary' sx={{ height: 40 }}
                            onClick={() => { setVariables({ ...variables, filter: { ...variables.filter, letsTitle: item.value } }) }} />
                    ))}
                </Stack>


                {(data && data.data?.length > 0) ?
                    data?.data?.map((item: any, index: any) => (
                        <Card key={index} sx={{ p: 2, mb: 2, cursor: 'pointer', ":hover": { boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' } }}
                            onClick={() => navigate(routes.user.activityDetail.replace(':id', item._id))}>
                            <Stack
                                direction={{ xs: 'column', md: 'row' }}
                                justifyContent={"space-between"}
                                alignItems={"center"}>
                                <Stack
                                    direction={{ xs: 'column', md: "row" }}
                                    gap={2}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{ p: 2 }}>
                                    {
                                        item.letsTitle === "letsRelax" ? <img src={LetsRelax} alt='letsrelax' width={40} /> :
                                            item.letsTitle === "letsTry" ? <img src={LetsTry} alt='letstry' width={40} /> :
                                                item.letsTitle === "letsLearn" ? <img src={LetsLearn} alt='letslearn' width={40} /> :
                                                    item.letsTitle === "letsMove" ? <img src={LetsMove} alt='letsmove' width={40} /> :
                                                        item.letsTitle === "letsConnect" ? <img src={LetsConnect} alt='letsconnect' width={40} /> : null
                                    }
                                    <Stack direction={"column"} width={{ xs: "100%", md: 150, lg: 200, xl: 250 }} spacing={1} alignItems={{ xs: 'center', md: "flex-start" }} justifyContent={"center"}>
                                        <Typography variant='h4'>{item.title}</Typography>
                                        <Typography>{categories.find((category: any) => category.value === item?.letsTitle)?.label}</Typography>
                                    </Stack>
                                    <Divider
                                        orientation="vertical"
                                        flexItem />
                                    <Stack direction={"column"} spacing={1} alignItems={{ xs: 'center', md: "flex-start" }} justifyContent={"center"}>
                                        <Typography variant='h6'>{item.sessionName}</Typography>
                                        <Typography>{item.location}</Typography>
                                        <Typography>for {item.sessionAllowedDepartments?.join(', ')}</Typography>

                                    </Stack>
                                </Stack>
                                <Stack direction={"column"} spacing={1} alignItems={"center"} justifyContent={"center"}>
                                    <Typography variant='h6'>{item.sessionTimeInterval}</Typography>
                                    <Button
                                        variant={!item?.applied ? 'contained' : 'outlined'}
                                        color={!item?.applied ? 'secondary' : 'error'}
                                        onClick={(event: any) => {
                                            event.stopPropagation();
                                            setSelectedActivity(item)
                                        }}>
                                        {!item?.applied ? "Join Activity" : "Leave Activity"}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Card>
                    )) : <Typography textAlign={"center"} variant={"h6"} mt={5}>There is no activity available</Typography>}

                {(data && data.data?.length > 0) && <Stack direction="row" justifyContent="center" mt={5} mb={5}>
                    <Pagination
                        count={Math.ceil((data as any)?.count / variables.pagination.pageSize)}
                        page={variables.pagination.page}
                        onChange={(e, page) => {
                            setVariables({
                                ...variables,
                                pagination: {
                                    ...variables.pagination,
                                    page: page
                                }
                            })
                        }}
                        color="primary"
                    />
                </Stack>}
            </Grid>
        </Grid>

        <Dialog open={selectedActivity !== null} onClose={() => setSelectedActivity(null)}
            sx={{
                '& .MuiDialog-paper': {
                    padding: 1,
                    borderRadius: 2
                }
            }}>
            <DialogTitle>
                {!selectedActivity?.applied ? "Join Activity" : "Leave Activity"}
                <Divider />
            </DialogTitle>
            <DialogContent sx={{ minWidth: { xs: '100%', md: 400 } }}>

                <Stack direction={{ xs: 'column', md: "row" }} gap={2} justifyContent="space-between" alignItems="center">
                    <Box>
                        {!selectedActivity?.applied ? <Typography>
                            Are you sure you want to join <b>{selectedActivity?.title}</b> ? <br /><br />
                        </Typography> : <Typography>
                            Are you sure you want to leave <b>{selectedActivity?.title}</b> ? <br /><br />
                        </Typography>}
                        <Typography>
                            <b>Location:</b> {selectedActivity?.location}
                        </Typography>
                        <Typography>
                            <b>Session:</b> {selectedActivity?.sessionName}
                        </Typography>
                        <Typography>
                            <b>Time:</b> {selectedActivity?.sessionTimeInterval}
                        </Typography>
                    </Box>
                    <Box sx={{ width: 200 }}>
                        {selectedActivity?.letsTitle === "letsRelax" && <Lottie animationData={LetsRelaxAnimation} loop={true} />}
                        {selectedActivity?.letsTitle === "letsTry" && <Lottie animationData={LetsTryAnimation} loop={true} />}
                        {selectedActivity?.letsTitle === "letsLearn" && <Lottie animationData={LetsLearnAnimation} loop={true} />}
                        {selectedActivity?.letsTitle === "letsMove" && <Lottie animationData={LetsMoveAnimation} loop={true} />}
                        {selectedActivity?.letsTitle === "letsConnect" && <Lottie animationData={LetsConnectAnimation} loop={true} />}
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSelectedActivity(null)}>Cancel</Button>
                {!selectedActivity?.applied ? <Button variant='contained' color="secondary"
                    disabled={joinLoading}
                    onClick={() => {
                        handleJoin()
                    }}
                >{joinLoading ? <CircularProgress size="1rem" /> : "Join Activity"}</Button> :
                    <Button variant='contained' color="error"
                        disabled={leaveLoading}
                        onClick={() => {
                            handleLeave()
                        }}
                    >{leaveLoading ? <CircularProgress size="1rem" /> : "Leave Activity"}</Button>}
            </DialogActions>
        </Dialog>
    </DashboardLayout >
}

export default Activities