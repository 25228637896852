import { LoadingButton } from '@mui/lab'
import { Grid, IconButton, Typography } from '@mui/material'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { TextField } from 'components'
import { useResetPasswordMutation } from 'apps/auth/context'
import { enqueueSnackbar } from 'notistack'

const passwordCheck = yup.string().min(8, "En az 8 karakter içermeli")
    .matches(/^(?=.*[a-z])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/^(?=.*[A-Z])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/^(?=.*[0-9])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/^(?=.*[!@#%&?.,+%-_])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/(?=.*[!'^+%.,/()&=?\-_*])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .required('Gerekli')

const validationSchema = yup.object().shape({
    currentPassword: passwordCheck,
    newPassword: passwordCheck,
    newPasswordConfirm: yup.string().oneOf([yup.ref('newPassword'), "null"], 'Yeni şifre ile aynı olmalı.').required("Gerekli"),
})



export const ChangePasswordSection = () => {

    const [resetPassword, { isLoading, isSuccess, isError, error }] = useResetPasswordMutation()

    const [visibility, setVisibility] = useState<Array<string>>([])

    const initialValues = {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
    }

    const handleChangeVisibility = (value: string) => {
        const isFind = visibility?.find((item) => item === value)
        if (isFind) {
            setVisibility(visibility?.filter((item) => item !== value))
        } else {
            setVisibility([...visibility, value])
        }

    }

    const handleChangePassword = async (values: any, setErrors: any, resetForm: any) => {

        if (values?.currentPassword === values?.newPassword) {
            setErrors({ newPassword: "Password cannot be the same as the current password" })
            return
        }

        try {
            await resetPassword({ oldPassword: values?.currentPassword, password: values?.newPassword ?? '' })
            resetForm()
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar('Password changed successfully', { variant: 'success' })
        }
    }, [isSuccess])

    useEffect(() => {
        if (isError) {
            enqueueSnackbar((error as any)?.error?.message?.en || 'An error occurred', { variant: 'error' })
            console.log(error)
        }
    }, [isError])

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { setErrors, resetForm }) => {
                console.log({ values });
                handleChangePassword(values, setErrors, resetForm)
            }}
        >
            {
                ({ errors, values, handleSubmit, isValid, setFieldValue, touched, handleBlur }) => {


                    return (
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Typography variant='h3'>
                                    Reset Password
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Current Password"
                                    type={'password'} // {visibility.includes("newPassword") ? 'text' : 'password'}
                                    name='currentPassword'
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                    variant='outlined'
                                    handleBlur={handleBlur}
                                    
                                />




                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'initial' } }}></Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="New Password"
                                    type={'password'} // {visibility.includes("newPassword") ? 'text' : 'password'}
                                    name='newPassword'
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                    variant='outlined'
                                    handleBlur={handleBlur}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'initial' } }}></Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="New Password Confirm"
                                    type={'password'} // {visibility.includes("newPassword") ? 'text' : 'password'}
                                    name='newPasswordConfirm'
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                    variant='outlined'
                                    handleBlur={handleBlur}
                                />
                            </Grid>

                            <Grid item xs={12} display="flex" justifyContent="start">
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    onClick={() => handleSubmit()}
                                    disabled={!isValid}
                                >
                                    Change Password
                                </LoadingButton>
                            </Grid>


                        </Grid>
                    )
                }
            }
        </Formik>
    )
}
