import { Route } from 'react-router-dom'

import { Dashboard, Activities, Applications, Statistics, ActivityDetail,Calendar, Settings } from '../pages/user';
import { routes } from './routes';
import { Profile } from 'apps/profile/pages';


export const UserRouter = () => {
    return (
        <>
            <Route path="/" element={<Dashboard />} />
            <Route path={routes.user.activities} element={<Activities />} />
            <Route path={routes.user.activityDetail} element={<ActivityDetail />} />
            <Route path={routes.user.applications} element={<Applications />} />
            <Route path={routes.user.statistics} element={<Statistics />} />
            <Route path={routes.user.profile} element={<Profile />} />
            <Route path={routes.user.calendar} element={<Calendar />} />
            <Route path={routes.user.settings} element={<Settings />} />
            <Route path="*" element={<Dashboard />} />
        </>
    )
}
