import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';


interface IRichTextField {
    values: any,
    name: string,
    setFieldValue: any,
    placeholder: string
}

export const RichTextField = ({ values, name, setFieldValue, placeholder }: IRichTextField) => {

    return <ReactQuill
        placeholder={placeholder}
        value={values[name]}
        onChange={(value: any) => {
            setFieldValue(name, value)
        }}
        style={{
            height: 200,
            borderRadius: 10,
        }}
    />
}

