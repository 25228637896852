import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, Avatar, Box, Link } from '@mui/material'
import { ExitToApp, AccountCircle } from '@mui/icons-material';

import User from 'assets/user.png';
import { useAppSelector } from 'context';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from 'apps/auth/routes/routes';
import { Iconify } from 'components';
import { useUserQuery } from 'apps/auth/context';


export interface ProfileBoxProps {
    handleClick: (e: any) => void;
    anchorEl: any;
    open: boolean;
    handleClose: () => void;
    handleAccount: () => void;
    handleLogout: () => void;
    handleSettings: () => void;
};

const ProfileBox = ({ handleClick, anchorEl, open, handleClose, handleAccount, handleLogout, handleSettings }: ProfileBoxProps) => {

    const { t } = useTranslation();
    const { name, surname, role, email, profileImage } = useAppSelector(state => state.auth)
    const { data } = useUserQuery({});

    const LinkItem = ({ children, to }: any) => {

        return (
            <MenuItem sx={{ width: 150 }}>
                <Link component={RouterLink} to={to}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'MenuText',

                        '&:hover': {
                            textDecoration: 'none',
                        }
                    }}
                >
                    {children}
                </Link>
            </MenuItem>
        )
    }

    return (
        <>

            <Box sx={{ mb: 5, mt: 4, mx: 1, cursor: 'pointer', backgroundColor: '#ffffff99' }} flexDirection="row"
                display="flex" alignItems="center" p={1}
                onClick={handleClick}
            >
                <img alt='profile_image' style={{ objectFit: 'cover', width: 40, height: 40, border: '1px solid #ccc', borderRadius: 20 }}
                    src={data?.profileImage?.publicUrl ?? User} />
                <Box sx={{ ml: 2 }}>
                    <Box sx={{ fontWeight: 600, color: '#222', textTransform: 'capitalize' }}>
                        {name ?? ''} &nbsp;
                        {surname ?? ''}
                    </Box>
                    <Box sx={{ fontSize: 12, color: '#666' }}>
                        {email ?? ''}
                    </Box>
                </Box>

            </Box>

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {/* {
                    userType === "candidate" ? (
                        <Box>
                            <LinkItem to={routes.candidate.dashboard.myAccount}>
                                <AccountCircle sx={{ marginRight: 1 }} />
                                {t("Hesap Bilgilerim")}
                            </LinkItem>
                        </Box>
                    ) : (
                        <Box>
                            <LinkItem to={routes.company.dashboard.myCompany}>
                                <AccountCircle sx={{ marginRight: 1 }} />
                                {t("Şirket Bilgilerim")}
                            </LinkItem>
                        </Box>
                    )
                } */}
                <MenuItem onClick={handleAccount}>
                    <Iconify icon="uil:user" width={24} sx={{ marginRight: 1 }} />
                    Profile
                </MenuItem>

                <MenuItem onClick={handleSettings}>
                    <Iconify icon="uil:setting" width={24} sx={{ marginRight: 1 }} />
                    Settings
                </MenuItem>

                <MenuItem onClick={handleLogout}>
                    <ExitToApp sx={{ marginRight: 1 }} />
                    Logout
                </MenuItem>
            </Menu>
        </>
    )
}

export default ProfileBox