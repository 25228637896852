import { Card } from '@mui/material'
import React from 'react'



interface ICustomTabPanelProps {
    value: any,
    index: any,
    children: any,
}


export const CustomTabPanel = ({ value, index, children }: ICustomTabPanelProps) => {

    return (
        <Card
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{
                p: 3
            }}
        >
            {children}
        </Card>
    )

}
