
import { useEffect, useMemo, useState } from 'react';
import { Button, Card, Grid, Stack, Typography, TextField as Textt, CircularProgress, Box } from '@mui/material'
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout'
import { useFormik } from 'formik';
import { updateUserValidationSchema, compressImage, saveImageAndGetUrl } from 'utils';
import dayjs from 'dayjs';
import { TextField, DateField, RichTextField, Iconify, } from 'components';
import { useUserQuery, useUpdateUserMutation } from 'apps/auth/context';
import { useLazyProfileImagePresignedUrlQuery, useProfileImagePresignedUrlQuery } from 'apps/profile/context';
import { enqueueSnackbar } from 'notistack';
import User from 'assets/user.png'
import { setName, setSurname } from 'apps/auth/context/slices/auth'
import { useAppDispatch } from "context";
import UserBg from 'assets/user-bg.jpg'

const Profile = () => {

    const { data, isLoading, isFetching, error, isError } = useUserQuery({})
    const [updateUser, { isLoading: idLoadingUpdate, data: dataUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate }] = useUpdateUserMutation()
    const { data: presignedUrlData, isLoading: presignedUrlLoading, isFetching: presignedUrlFetching, error: presignedUrlError, isError: presignedUrlIsError } = useProfileImagePresignedUrlQuery({})
    const [getPresignUrl] = useLazyProfileImagePresignedUrlQuery()
    const dispatch = useAppDispatch()
    const [profileImage, setProfileImage] = useState<any>(null)

    const initialValues = useMemo<any>(() => {
        return {
            name: data?.name,
            surname: data?.surname || '',
            birthDate: dayjs(data?.birthDate) || '',
            gender: data?.gender || null,
            country: data?.country || '',
            city: data?.city || '',
            address: data?.address || '',
            profileImage: data?.profileImage || null,
            description: data?.description || '',
        }
    }, [data])


    const handleSetProfileImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = (event.target as HTMLInputElement).files?.[0];
        const compressedImage = file && await compressImage(file, 0.5)
        console.log({ file, compressedImage });
        setProfileImage(compressedImage)
    }

    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: updateUserValidationSchema,
        enableReinitialize: true,
        onSubmit: async values => {
            console.log(values, 'valuessssss')
            try {
                let file;

                console.log("zzzz")
                if (profileImage) {
                    const presigneUrl = await getPresignUrl({})
                    console.log(presigneUrl, "presigneUrl")

                    const isSaved = await saveImageAndGetUrl({
                        file: profileImage,
                        presignUrl: presigneUrl?.data?.presignedUrl?.uploadPresignedUrl || ''
                    })
                    file = presigneUrl?.data?.file
                }
                console.log(file, 'file')
                console.log("askmdfkmsdfmksmkdf")

                updateUser({
                    ...values,
                    birthDate: values.birthDate.toDate(),
                    profileImage: file ? file : undefined
                })
            } catch (error) {
                console.log(error)
            }
        }
    })


    useEffect(() => {
        if (isSuccessUpdate) {
            enqueueSnackbar('Profile updated successfully', { variant: 'success' })
            dispatch(setName(values.name))
            dispatch(setSurname(values.surname))
        }
    }, [isSuccessUpdate])

    useEffect(() => {
        if (isErrorUpdate) {
            enqueueSnackbar('Profile update failed', { variant: 'error' })
        }
    }, [isErrorUpdate])


    return <DashboardLayout >
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h1'>My Profile </Typography>
                </Stack>
            </Grid>



            {!isLoading && <Grid item xs={12} >

                <Card sx={{ p: 5, width: '100%' }}>
                    <img
                        src={UserBg}
                        alt="profileBg" style={{ width: '100%', height: "100px", objectFit: 'cover', borderRadius: 20 }} />

                    <Box sx={{ marginTop: -5, marginLeft: 3, backgroundColor: '#fff', position: 'relative', width: '100px', borderRadius: '50%', height: '100px', cursor: 'pointer' }}>
                        <Box
                            component="label"
                            htmlFor="profile_image_input"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => { }}>
                            <Box
                                component={"img"}
                                src={profileImage ? URL.createObjectURL(profileImage) : data?.profileImage?.publicUrl ?? User}
                                alt='avatar'
                                style={{ width: 100, height: 100, border: '5px solid white', borderRadius: '50%', objectFit: 'cover', }} />

                            <EditButton />
                        </Box>
                        <Box
                            component="input"
                            type="file"
                            accept="image/*"
                            sx={{ display: 'none' }}
                            id="profile_image_input"
                            // value={profileImage}
                            onChange={handleSetProfileImage}
                        />
                    </Box>
                    <Grid container spacing={3} mt={1} >
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Name"
                                values={values}
                                name={"name"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Surname"
                                values={values}
                                name={"surname"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Textt
                                label="Email"
                                disabled
                                fullWidth
                                value={data?.email}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DateField
                                label="Birth Date"
                                values={values}
                                name={"birthDate"}
                                setFieldValue={setFieldValue}
                                minDate={dayjs().subtract(100, 'year')}
                                maxDate={dayjs().subtract(15, 'year')}
                            />
                        </Grid>
                        {!!values?.gender && <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label='Gender'
                                name='gender'
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                handleBlur={handleBlur}
                                datas={[
                                    { label: "Male", value: "male" },
                                    { label: "Female", value: "female" },
                                    { label: "Other", value: "other" },
                                ]}
                            />
                        </Grid>}
                        {!!(values?.gender === null) && <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label='Gender'
                                name='gender'
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                handleBlur={handleBlur}
                                datas={[
                                    { label: "Male", value: "male" },
                                    { label: "Female", value: "female" },
                                    { label: "Other", value: "other" },
                                ]}
                            />
                        </Grid>}
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Country"
                                values={values}
                                name={"country"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="City"
                                values={values}
                                name={"city"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Address"
                                values={values}
                                multiline
                                rows={2}
                                name={"address"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RichTextField
                                placeholder='Write something about yourself...'
                                values={values}
                                name={"description"}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>


                        <Grid item xs={12} mt={5}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    handleSubmit()
                                }}
                            >
                                {isLoading ? <CircularProgress size={"1rem"} /> : 'Update Profile'}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>

            </Grid>}

        </Grid >
    </DashboardLayout >
}

export default Profile


const EditButton = () => {

    return (
        <Box
            position={'absolute'}
            bottom={5}
            right={5}
            border={"1px solid #ddd"}
            width={30}
            height={30}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            borderRadius={15}
            bgcolor={'#fff'}
        >
            <Iconify icon={"basil:edit-outline"} sx={{ width: 20, height: 20, cursor: 'pointer', color: 'primary.dark' }} />
        </Box>
    )
}

