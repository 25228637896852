import { Route } from 'react-router-dom'

import { Dashboard, Activities, Users, Statistics, ActivityDetail, CreateActivity, UpdateActivity, UpdateActivitySession, Settings } from '../pages/admin';
import { routes } from './routes';
import { Profile } from 'apps/profile/pages';


export const AdminRouter = () => {
    return (
        <>
            <Route path="/" element={<Dashboard />} />
            <Route path={routes.admin.activities} element={<Activities />} />
            <Route path={routes.admin.activityDetail} element={<ActivityDetail />} />
            <Route path={routes.admin.createActivity} element={<CreateActivity />} />
            <Route path={routes.admin.updateActivity} element={<UpdateActivity />} />
            <Route path={routes.admin.updateActivitySession} element={<UpdateActivitySession />} />
            <Route path={routes.admin.users} element={<Users />} />
            <Route path={routes.admin.statistics} element={<Statistics />} />
            <Route path={routes.admin.profile} element={<Profile />} />
            <Route path={routes.admin.settings} element={<Settings />} />
            <Route path="*" element={<Dashboard />} />
        </>
    )
}
