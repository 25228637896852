

import {
    IBaseGraphqlError,
    ILoginInput, ILoginResponse, ILoginResult,
    ILogoutInput, ILogoutResult,
    IRequestResetPasswordInput, IRequestResetPasswordResult,
    IConfirmRegistrationInput, IConfirmRegistrationResult, IRegisterByResult,
    IResendRegistrationByUserConfirmationInput, IResendRegistrationByUserConfirmationResult,
    IResetPasswordInput, IResetPasswordResult,
    IResetPasswordAfterRequestInput, IResetPasswordAfterRequestResult,
    IConfirmRegistrationByUserInput, IConfirmRegistrationByUserResult,
    IResendRegistrationByUserConfirmationRequest, IResendRegistrationConfirmationInput,

    loginQuery, logoutQuery, requestResetPasswordQuery, registerByQuery,
    confirmRegistrationQuery, resendRegistrationByUserConfirmationQuery,
    resetPasswordQuery, resetPasswordAfterRequestQuery, confirmRegistrationByUserQuery,
    resendRegistrationConfirmationQuery,
} from "corede-common";
import {
    IRegisterByInput,
    IUsersInput, IUsersResponse, IUsersResult, usersQuery, IUserFilter,
    IUserInput, IUserResponse, IUserResult, userQuery,
    IUserByAdminInput, IUserByAdminResponse, IUserByAdminResult, userByAdminQuery,
    removeUserQuery, IRemoveUserFilterInput, IRemoveUserResult,
    updateUserQuery, IUpdateUserInput, IUpdateUserResult,
} from "corede-common-umbrella";
import { commonApi } from "context/commonApi";
import { print } from "graphql"


const authApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({
        // queries

        users: builder.query<IUsersResult, IUsersInput>({
            query: (variables: IUsersInput & IUserFilter & { input: { filter: { role?: string } } }) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(usersQuery),
                        variables: {
                            input: {
                                pagination: {
                                    page: variables?.pagination?.page || undefined,
                                    pageSize: variables?.pagination?.pageSize || undefined,
                                    sort: variables?.pagination?.sort || "",
                                },
                                filter: {
                                    role: "user"
                                }
                            }
                        }
                    })
                })
            },
            transformResponse: (response: IUsersResponse): IUsersResult => {
                return response.data.users
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            providesTags: ['users']
        }),

        user: builder.query<IUserResult, any>({
            query: () => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(userQuery),
                    })
                })
            },
            transformResponse: (response: IUserResponse): IUserResult => {
                return response.data.user
            },
            transformErrorResponse: (response: IBaseGraphqlError): IBaseGraphqlError => {
                return response
            },
            providesTags: ['user']
        }),

        userByAdmin: builder.query<IUserByAdminResult, IUserByAdminInput>({
            query: (variables: IUserByAdminInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(userByAdminQuery),
                        variables: {
                            input: {
                                userId: variables.userId,
                            }
                        }
                    })
                })
            },
            transformResponse: (response: IUserByAdminResponse): IUserByAdminResult => {
                return response.data.admin_user
            },
            transformErrorResponse: (response: IBaseGraphqlError): IBaseGraphqlError => {
                return response
            },
            providesTags: ['user']
        }),



        // mutations

        login: builder.mutation<ILoginResult, ILoginInput>({
            query: (variables: ILoginInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(loginQuery),
                        variables: {
                            input: {
                                email: variables.email,
                                password: variables.password,
                            },
                        },
                    })
                })
            },
            transformResponse: (response: ILoginResponse): ILoginResult => {
                return response?.data?.login
            },
            transformErrorResponse: (response: any): IBaseGraphqlError => {
                return response
            },
            invalidatesTags: []
        }),

        logout: builder.mutation<ILogoutResult, ILogoutInput>({
            query: (variables: ILogoutInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(logoutQuery),
                        variables: {
                            input: {
                                refreshToken: variables.refreshToken,
                            },
                        },

                    })
                })
            },
            transformResponse: (response: ILogoutResult) => {
                return response
            },
            transformErrorResponse: (response: any): IBaseGraphqlError => {
                return response
            },
            invalidatesTags: []
        }),

        requestResetPassword: builder.mutation<IRequestResetPasswordResult, IRequestResetPasswordInput>({
            query: (variables: IRequestResetPasswordInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(requestResetPasswordQuery),
                        variables: {
                            input: {
                                email: variables.email,
                            },
                        },

                    })
                })
            },
            transformResponse: (response: IRequestResetPasswordResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: []
        }),

        registerBy: builder.mutation<IRegisterByResult, IRegisterByInput>({
            query: (variables: IRegisterByInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(registerByQuery),
                        variables: {
                            input: {
                                name: variables.name,
                                surname: variables.surname,
                                email: variables.email,
                                role: variables.role,
                                department: variables.department,
                            },
                        },

                    })
                })
            },
            transformResponse: (response: IRegisterByResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ["users"]
        }),

        confirmRegistration: builder.mutation<IConfirmRegistrationResult, IConfirmRegistrationInput>({
            query: (variables: IConfirmRegistrationInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(confirmRegistrationQuery),
                        variables: {
                            input: {
                                token: variables.token,
                                code: variables.code,
                            },
                        },

                    })
                })
            },
            transformResponse: (response: IConfirmRegistrationResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: []
        }),

        resendRegistrationByUserConfirmation: builder.mutation<IResendRegistrationByUserConfirmationResult, IResendRegistrationByUserConfirmationInput>({
            query: (variables: IResendRegistrationByUserConfirmationInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(resendRegistrationByUserConfirmationQuery),
                        variables: {
                            input: {
                                email: variables.email,
                            },
                        },

                    })
                })
            },
            transformResponse: (response: IResendRegistrationByUserConfirmationResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: []
        }),

        resetPassword: builder.mutation<IResetPasswordResult, IResetPasswordInput>({
            query: (variables: IResetPasswordInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(resetPasswordQuery),
                        variables: {
                            input: {
                                oldPassword: variables.oldPassword,
                                password: variables.password,
                            },
                        },

                    })
                })
            },
            transformResponse: (response: IResetPasswordResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: []
        }),

        resetPasswordAfterRequest: builder.mutation<IResetPasswordAfterRequestResult, IResetPasswordAfterRequestInput>({
            query: (variables: IResetPasswordAfterRequestInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(resetPasswordAfterRequestQuery),
                        variables: {
                            input: {
                                token: variables.token,
                                password: variables.password,
                            },
                        },

                    })
                })
            },
            transformResponse: (response: IResetPasswordAfterRequestResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError): IBaseGraphqlError => {
                return response
            },
            invalidatesTags: []
        }),

        confirmRegistrationByUser: builder.mutation<IConfirmRegistrationByUserResult, IConfirmRegistrationByUserInput>({
            query: (variables: IConfirmRegistrationByUserInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(confirmRegistrationByUserQuery),
                        variables: {
                            input: {
                                token: variables.token,
                                code: variables.code,
                                resetPasswordToken: variables.resetPasswordToken,
                                password: variables.password,
                            },
                        },

                    })
                })
            },
            transformResponse: (response: IConfirmRegistrationByUserResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: []

        }),

        resendRegistrationByUser: builder.mutation<IResendRegistrationByUserConfirmationRequest, IResendRegistrationConfirmationInput>({
            query: (variables: IResendRegistrationConfirmationInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(resendRegistrationConfirmationQuery),
                        variables: {
                            input: {
                                email: variables.email,
                            },
                        },
                    })
                })
            },
            transformResponse: (response: IResendRegistrationByUserConfirmationRequest) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: []
        }),

        removeUser: builder.mutation<IRemoveUserResult, IRemoveUserFilterInput>({
            query: (variables: IRemoveUserFilterInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(removeUserQuery),
                        variables: {
                            filter: {
                                userId: variables.userId,
                            }
                        },
                    })
                })
            },
            transformResponse: (response: IRemoveUserResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ["users"]
        }),

        updateUser: builder.mutation<IUpdateUserResult, IUpdateUserInput>({
            query: (values: IUpdateUserInput) => {
                return ({
                    url: "",
                    body: JSON.stringify({
                        query: print(updateUserQuery),
                        variables: {
                            input: values
                        },
                    })
                })
            },
            transformResponse: (response: IUpdateUserResult) => {
                return response
            },
            transformErrorResponse: (response: IBaseGraphqlError) => {
                return response
            },
            invalidatesTags: ["users", "user"]
        }),
    }),
})

export const {
    useUsersQuery, useLazyUsersQuery,
    useUserQuery, useLazyUserQuery,
    useUserByAdminQuery, useLazyUserByAdminQuery,

    useLoginMutation,
    useLogoutMutation,
    useRequestResetPasswordMutation,
    useRegisterByMutation,
    useConfirmRegistrationMutation,
    useResendRegistrationByUserConfirmationMutation,
    useResetPasswordMutation,
    useResetPasswordAfterRequestMutation,
    useConfirmRegistrationByUserMutation,
    useResendRegistrationByUserMutation,
    useRemoveUserMutation,
    useUpdateUserMutation

} = authApi