import { Delete, RemoveRedEye } from '@mui/icons-material'
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout'
import { routes } from 'apps/dashboard/routes/routes'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TextField } from 'components'
import { createUserValidationSchema } from 'utils/validations'
import { useFormik } from 'formik'
import { useRegisterByMutation, useUsersQuery, useRemoveUserMutation, useResendRegistrationByUserConfirmationMutation } from 'apps/auth/context'
import { useAddToBlacklistMutation, useRemoveFromBlacklistMutation } from 'apps/dashboard/context'
import { enqueueSnackbar } from 'notistack'
import { departments } from 'utils'
import User from 'assets/user.png'
import moment from 'moment'
import parse from 'html-react-parser';
import UserBg from "assets/user-bg.jpg";

const Users = () => {

    const navigate = useNavigate()
    const [selectedUser, setSelectedUser] = useState<any>(null)
    const [selectedUserForDelete, setSelectedUserForDelete] = useState<any>(null)
    const [openCreateUser, setOpenCreateUser] = useState<boolean>(false)
    const [registerBy, { isLoading, isError, isSuccess, data, error }] = useRegisterByMutation()
    const [removeUser, { isLoading: isRemoving, isError: isRemoveError, isSuccess: isRemoveSuccess, data: removeData, error: removeError }] = useRemoveUserMutation()
   
    const [variables, setVariables] = useState<any>({ pagination: { page: 1, pageSize: 10 } })
    const { data: usersData } = useUsersQuery(variables)

    const [removeFromBlacklist, { isLoading: isRemovingFromBlacklist, isError: isRemoveFromBlacklistError, isSuccess: isRemoveFromBlacklistSuccess, data: removeDataFromBlacklist, error: removeErrorFromBlacklist }] = useRemoveFromBlacklistMutation()
    const [addToBlacklist, { isLoading: isAddingToBlacklist, isError: isAddToBlacklistError, isSuccess: isAddToBlacklistSuccess, data: addDataToBlacklist, error: addErrorToBlacklist }] = useAddToBlacklistMutation()
    const [resendRegistrationByUserConfirmation, { isSuccess: isResendSuccess, isError: isResendError, error: resendError }] = useResendRegistrationByUserConfirmationMutation()

    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm } = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: '',
            department: ''
        },
        validationSchema: createUserValidationSchema,
        onSubmit: async values => {
            try {
                await registerBy({ name: values.name, surname: values.surname, email: values.email, department: values.department, role: 'user' });
            } catch (error) {
                enqueueSnackbar("Create User Failed", { variant: 'error' });
            }
        }
    })


    const handleCloseCreateUser = () => {
        setOpenCreateUser(false)
        resetForm()
    }

    const handleDeleteUser = () => {
        removeUser({ userId: selectedUserForDelete._id })
    }

    const handleBlackList = () => {
        if (selectedUser?.isBlacklisted) {
            removeFromBlacklist({ userId: selectedUser._id })
        } else {
            addToBlacklist({ userId: selectedUser._id })
        }
    }

    const handleResendConfirmation = (data: any) => {
        resendRegistrationByUserConfirmation({ email: data.email })
    }

    useEffect(() => {
        if (isError) {
            enqueueSnackbar((error as any)?.error?.message?.en ?? "Something went wrong!", { variant: 'error' });
        }
    }, [isError, enqueueSnackbar, error]);

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar("User Created Successfully", { variant: 'success' });
            handleCloseCreateUser()
        }
    }, [isSuccess, enqueueSnackbar]);

    useEffect(() => {
        if (isRemoveError) {
            enqueueSnackbar((removeError as any)?.error?.message?.en ?? "Something went wrong!", { variant: 'error' });
        }
    }, [isRemoveError, enqueueSnackbar]);

    useEffect(() => {
        if (isRemoveSuccess) {
            enqueueSnackbar("User Deleted Successfully", { variant: 'success' });
            setSelectedUserForDelete(null)
        }
    }, [isRemoveSuccess, enqueueSnackbar]);

    useEffect(() => {
        if (isRemoveFromBlacklistError) {
            enqueueSnackbar((removeErrorFromBlacklist as any)?.error?.message?.en ?? "Something went wrong!", { variant: 'error' });
        }
    }, [isRemoveFromBlacklistError, enqueueSnackbar]);

    useEffect(() => {
        if (isRemoveFromBlacklistSuccess) {
            enqueueSnackbar("User Removed from Blacklist Successfully", { variant: 'success' });
            setSelectedUser(null)
        }
    }, [isRemoveFromBlacklistSuccess, enqueueSnackbar]);

    useEffect(() => {
        if (isAddToBlacklistError) {
            enqueueSnackbar((addErrorToBlacklist as any)?.error?.message?.en ?? "Something went wrong!", { variant: 'error' });
        }
    }, [isAddToBlacklistError, enqueueSnackbar]);

    useEffect(() => {
        if (isAddToBlacklistSuccess) {
            enqueueSnackbar("User Added to Blacklist Successfully", { variant: 'success' });
            setSelectedUser(null)
        }
    }, [isAddToBlacklistSuccess, enqueueSnackbar]);

    useEffect(() => {
        if (isResendError) {
            enqueueSnackbar((resendError as any)?.error?.message?.en ?? "Something went wrong!", { variant: 'error' });
        }
        if (isResendSuccess) {
            enqueueSnackbar("Email has been sent successfully", { variant: 'success' });
        }

    }, [isResendSuccess, isResendError, resendError])



    return <DashboardLayout>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h1'>Users</Typography>
                    <Button variant="contained" color="secondary"
                        onClick={() => setOpenCreateUser(true)}>Create User</Button>
                </Stack>
            </Grid>

            <Grid item xs={12} >
                <Box sx={{ py: 2, px: 4, bgcolor: 'primary.lighter', borderRadius: 2 }}>
                    <Typography variant='body1'>
                        This page is for managing users. You can create, delete and view details of users. You can also see the list of activities that the users have participated in.
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Card >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Name </TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell align='center'>Department</TableCell>
                                    <TableCell align='center'>Status</TableCell>
                                    <TableCell>Detail / Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    usersData?.data.map((data: any, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell>{(variables.pagination.page - 1) * variables.pagination.pageSize + index + 1} </TableCell>
                                            <TableCell>{data.name} {data.surname}</TableCell>
                                            <TableCell>{data.email}</TableCell>
                                            <TableCell align='center'>{data.department}</TableCell>
                                            <TableCell align='center'><Stack direction={"column"} gap={1}>
                                                {data.status?.slice(0, 1).toUpperCase() + data.status?.slice(1)}
                                                {data.status === "pending" && <Typography fontSize={"small"} fontWeight={"bold"}
                                                    onClick={() => handleResendConfirmation(data)}
                                                    sx={{ cursor: 'pointer', color: 'info.main' }}>
                                                    Resend Mail
                                                </Typography>}

                                            </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" spacing={1}>
                                                    <Button variant="outlined" sx={{ height: 40 }} color="primary"
                                                        onClick={() => {
                                                            setSelectedUser(data)
                                                        }}>
                                                        <RemoveRedEye sx={{ fontSize: '18px' }} />
                                                    </Button>
                                                    <Button variant="outlined" sx={{ height: 40 }} color="error"
                                                        onClick={() => {
                                                            setSelectedUserForDelete(data)
                                                        }}>
                                                        <Delete sx={{ fontSize: '18px' }} />
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Stack direction="row" justifyContent="center" mt={2} mb={2}>
                        <Pagination
                            count={Math.ceil((usersData as any)?.count / variables.pagination.pageSize)}
                            page={variables.pagination.page}
                            onChange={(e, page) => {
                                setVariables({
                                    ...variables,
                                    pagination: {
                                        ...variables.pagination,
                                        page: page
                                    }
                                })
                            }}
                            color="primary"
                        />
                    </Stack>
                </Card>
            </Grid>
        </Grid>


        <Dialog open={selectedUser !== null} onClose={() => setSelectedUser(null)}
            sx={{
                '& .MuiDialog-paper': {
                    padding: 1,
                    borderRadius: 2
                }
            }}>
            <DialogTitle>
                User Detail
                <Divider />
            </DialogTitle>
            <DialogContent sx={{ minWidth: { xs: '100%', md: '500px' }, position: 'relative' }}>
                <img
                    src={UserBg}
                    alt="profileBg" style={{ width: '100%', height: "120px", objectFit: 'cover', borderRadius: 20 }} />
                <img src={selectedUser?.profileImage?.publicUrl?.includes('http') ? selectedUser?.profileImage?.publicUrl : User}
                    alt='avatar'
                    style={{ width: 100, height: 100, borderRadius: '50px', objectFit: 'cover', border: '5px solid white', position: 'absolute', top: 10, left: 40, backgroundColor: 'white' }} />
                <Typography variant='h4' mt={3} textAlign="center">
                    {selectedUser?.name} {selectedUser?.surname}
                </Typography>
                <Typography variant='body1' textAlign="center" color="text.secondary">
                    {selectedUser?.email}
                </Typography>
                <Stack direction={{ xs: 'column', md: "row" }} alignItems="flex-start" >
                    <Stack direction={"column"} gap={1} px={1} mt={3} width={{ xs: '100%', md: '50%' }}>
                        <Typography>
                            <strong>Department:</strong> {selectedUser?.department ?? ' -'} <br />
                            <strong>Gender:</strong> {selectedUser?.gender ?? ' -'} <br />
                            <strong>Birth Date:</strong> {selectedUser?.birthDate ? moment(selectedUser?.birthDate).format('DD MMMM YYYY') : ' -'} <br />
                            {/* <strong>Phone Number:</strong> {selectedUser?.phoneNumber ?? ' -'} <br /> */}
                        </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{ mt: 3 }} />
                    <Stack direction={"column"} gap={1} px={3} mt={3} width={{ xs: '100%', md: '50%' }}>
                        <Typography>
                            <strong>Country:</strong> {selectedUser?.country ?? ' -'} <br />
                            <strong>City:</strong> {selectedUser?.city ?? ' -'} <br />
                            <strong>Address:</strong> {selectedUser?.address ?? ' -'} <br />
                        </Typography>
                    </Stack>
                </Stack>
                <Stack px={1} mt={3}>
                    <strong>Description:</strong>
                    <Box sx={{ width: '100%', bgcolor: '#eee', borderRadius: 2, px: 5, py: 2, mb: 2 }}>
                        <Typography> {parse(selectedUser?.description ?? ' -')}</Typography>
                    </Box>
                    <Typography>  <strong>Status:</strong> {selectedUser?.status?.toUpperCase()} </Typography>
                    <Typography>  <strong>Last Login:</strong>  {selectedUser?.lastLoginDate ? moment(selectedUser?.lastLoginDate).format('DD MMMM YYYY - HH:mm') : ' -'} </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSelectedUser(null)}>Cancel</Button>
                <Button
                    variant={!selectedUser?.isBlacklisted ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => handleBlackList()}
                >
                    {!selectedUser?.isBlacklisted ? 'Add to Blacklist' : 'Remove from Blacklist'}
                </Button>
            </DialogActions>
        </Dialog >

        <Dialog open={selectedUserForDelete !== null} onClose={() => setSelectedUserForDelete(null)}
            sx={{
                '& .MuiDialog-paper': {
                    padding: 1,
                    borderRadius: 2
                }
            }}>
            <DialogTitle>
                Remove User
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Are you sure you want to delete  <strong>{selectedUser?.name}</strong>? <br /> This action cannot be undone.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSelectedUserForDelete(null)}>Cancel</Button>
                <Button variant='contained' color="error" onClick={() => handleDeleteUser()}>Delete</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openCreateUser} onClose={() => handleCloseCreateUser()}
            sx={{
                '& .MuiDialog-paper': {
                    padding: 1,
                    borderRadius: 2
                }
            }}>
            <DialogTitle>
                Create User
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Please fill the form to create a new user.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Name"
                            values={values}
                            name={"name"}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Surname"
                            values={values}
                            name={"surname"}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Email"
                            values={values}
                            name={"email"}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Department"
                            values={values}
                            name={"department"}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            select
                            datas={departments}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions sx={{ px: 2, flexDirection: 'row', gap: 2 }}>
                <Button onClick={() => handleCloseCreateUser()}>Cancel</Button>
                <Button variant='contained' color="secondary" onClick={() => handleSubmit()}>Create User</Button>
            </DialogActions>
        </Dialog>


    </DashboardLayout >
}

export default Users