import { Box, Button, Container, Grid, TextField, Typography, Link, CircularProgress } from "@mui/material";
import Logo from "assets/hapag-logo.png";
import AuthImage from "assets/auth-img.png";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { forgotPasswordValidationSchema } from 'utils/validations';
import { routes } from "../routes/routes";
import { AuthLayout } from "../layouts";
import { useNavigate } from "react-router-dom";
import { useRequestResetPasswordMutation } from "apps/auth/context";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";


const ForgotPassword = () => {

  const navigate = useNavigate();
  const [requestResetPassword, { isLoading, error, isSuccess, isError, data }] = useRequestResetPasswordMutation();

  const { values, handleSubmit, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    validationSchema: forgotPasswordValidationSchema,
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      requestResetPassword({ email: values.email });
    }
  });

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Reset password link has been sent to your email', { variant: 'success' })
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      enqueueSnackbar((error as any)?.error?.message.en, { variant: 'error' })
    }
  }, [isError, error])


  return <AuthLayout>
    <Grid item xs={12} md={6} display={"flex"} height={"100vh"} justifyContent={'center'} alignItems={'center'}>

      <Box display={{ xs: 'flex', md: 'none' }} height={100} />
      <Box
        sx={{
          width: '90%',
          height: "80vh",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'center' },
          alignItems: 'center',
          gap: 5,
        }}
        px={{ xs: 1, md: 7 }}
      >
        <img src={Logo} alt="logo" width={220} />
        <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"}>
          <Typography fontSize={40} fontWeight={600} >
            Forgot Password
          </Typography>
        </Box>

        <Box display={"flex"} sx={{ width: '100%' }} flexDirection={"column"} gap={2} alignItems={"center"}>
          <Typography fontSize={15} fontWeight={400} textAlign={"center"}>
            Please enter your email address. You will receive a link to create a new password via email.
          </Typography>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={values.email}
            onChange={(e) => setFieldValue('email', e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={handleSubmit as any}
            disabled={!!(isLoading || !values.email || data)}
          >
            {isLoading ? <CircularProgress size={"1rem"} /> : "Forgot Password"}
          </Button>

          <Typography fontSize={15} fontWeight={500} >
            <Link
              onClick={() => navigate(routes.auth.login)}
              color={"#33424F"} underline={"hover"}
              sx={{ cursor: 'pointer' }}>
              Back to Login
            </Link>
          </Typography>

        </Box>
      </Box>
    </Grid>
  </AuthLayout >
}

export default ForgotPassword;
