
import { ThemeProvider, styled } from '@mui/material/styles';
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/tr';

// import ThemeProvider from 'minimal/theme';
import Router from 'Router';
import { store } from 'context';
import { theme } from 'theme';
import { SnackbarProvider } from 'components';
dayjs.extend(utc);
dayjs.extend(timezone);

// Türkçe locale ve zaman dilimini ayarla
dayjs.locale('tr');
dayjs.tz.setDefault('Europe/Istanbul');




const Wrapper = () => {


  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"}>
            <Router />
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default Wrapper


