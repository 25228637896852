
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { TextField as Field, IconButton, MenuItem } from "@mui/material"
import { useState } from "react"

interface ITextField {
    label?: string | ""
    variant?: any | 'outlined'
    values: any
    name: string
    setFieldValue: (field: string, value: any) => void
    handleBlur: (field: string) => void
    touched: any
    errors: any
    select?: boolean | false
    datas?: { value: string, label: string }[]
    isNumber?: boolean | false
    disabled?: boolean | false
    multiline?: boolean | false
    rows?: number | 1
    type?: string
    placeholder?: string
}

export const TextField = ({ label, variant, values, name, placeholder, setFieldValue, handleBlur, touched, errors, select, datas, isNumber, disabled, multiline, rows, type }: ITextField) => {

    const [visibility, setVisibility] = useState<string[]>([]);
    const handleChangeVisibility = () => {
        if (visibility.includes(name)) {
            setVisibility(visibility.filter(item => item !== name));
        } else {
            setVisibility([...visibility, name]);
        }
    }

    if (select) {
        return <Field
            fullWidth
            label={label}
            variant={variant}
            value={values[name]}
            disabled={disabled}
            onChange={e => setFieldValue(name, e.target.value)}
            onBlur={() => handleBlur(name)}
            error={touched[name] && errors[name] ? true : false}
            helperText={touched[name] && errors[name] ? errors[name] : null}
            select

        >
            {datas?.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Field>
    }

    return <Field
        fullWidth
        label={label}
        placeholder={placeholder}
        variant={variant}
        value={values[name]}
        disabled={disabled}
        multiline={multiline}
        type={type === "password" ? visibility.includes(name) ? 'text' : 'password' : type}
        rows={rows}
        onChange={!isNumber ? e => setFieldValue(name, e.target.value)
            : e => {
                if (/^[0-9]*$/.test(e.target.value)) {
                    // Eğer girdi sayıysa, değeri setFieldValue ile güncelle
                    setFieldValue(name, e.target.value);
                }
            }
        }
        onBlur={() => handleBlur(name)}
        error={touched[name] && errors[name] ? true : false}
        helperText={touched[name] && errors[name] ? errors[name] : null}
        select={select}
        InputProps={type === "password" ? {
            endAdornment: (
                <IconButton onClick={() => handleChangeVisibility()}>
                    {
                        visibility.includes(name) ? <Visibility /> : <VisibilityOff />
                    }
                </IconButton>
            )
        } : {}}
    />
}

