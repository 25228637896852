import { Box, Card, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import { CustomTabPanel } from 'components/tabs/CustomTabPanel'
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout'
import React from 'react'

import LockPersonIcon from '@mui/icons-material/LockPerson';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import { ChangePasswordSection } from './ChangePasswordSection';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';



const Settings = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };




    return (
        <DashboardLayout>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2} justifyContent="space-between">
                        <Stack direction="row" spacing={2} alignItems="center">
                            <h1>Settings</h1>
                        </Stack>

                    </Stack>
                </Grid>
                <Grid item xs={12}>


                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="icon position tabs example"
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab icon={<LockPersonIcon />} iconPosition="start" label="Reset Password" />
                    </Tabs>


                    <CustomTabPanel value={value} index={0}>
                        <ChangePasswordSection />
                    </CustomTabPanel>

                </Grid>
            </Grid>

        </DashboardLayout >
    )
}




export default Settings;