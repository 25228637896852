import React, { useState } from 'react';
import {
    EventApi,
    EventClickArg,
    EventContentArg,
    formatDate,
} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useUserActivitiesQuery } from 'apps/dashboard/context'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';



const Calendar: React.FC = () => {
    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);
    const navigate = useNavigate();

    const [variables, setVariables] = useState<any>({
        pagination: { page: 1, pageSize: 10, sort: 'title' },
        filter: { letsTitle: '' }
    })

    const { data, error, isLoading } = useUserActivitiesQuery(variables)
    let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

    console.log(data, 'data')


    const events = data?.data?.map((item, index) => ({
        id: item._id,
        title: item.title,
        start: moment(item.date).format('YYYY-MM-DD'),
    }));

    console.log(events, 'events')

    const handleEventClick = (clickInfo: EventClickArg) => {
        navigate(`/activities/${clickInfo.event.id}`)
    };

    const handleEvents = (events: EventApi[]) => {
        setCurrentEvents(events);
    };

    return <DashboardLayout>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h1'>Calendar </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ py: 2, px: 4, mb: 5, bgcolor: 'primary.lighter', borderRadius: 2 }}>
                    <Typography variant='body1'>
                        On this page you can view events via the calendar. You can select the previous and next months and view the details of the events.                    </Typography>
                </Box>
                {data && <FullCalendar
                    plugins={[dayGridPlugin,]}
                    headerToolbar={{
                        left: 'prev',
                        center: 'title',
                        right: 'next'
                    }}
                    initialView='dayGridMonth'
                    dayMaxEvents={true}
                    weekends={weekendsVisible}
                    initialEvents={events} // alternatively, use the `events` setting to fetch from a feed
                    eventContent={renderEventContent} // custom render function
                    eventClick={handleEventClick}
                    eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                />}
            </Grid>
        </Grid>
    </DashboardLayout>
};

function renderEventContent(eventContent: EventContentArg) {
    return (
        <>
            <b>{eventContent.timeText}</b>
            <i>{eventContent.event.title}</i>
        </>
    );
}


export default Calendar;
