import { Box, Button, Card, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout'
import React, { useState } from 'react'
import HelloImage from 'assets/hello.png'
import { Delete, Edit, Event, Group, RemoveRedEye } from '@mui/icons-material'
import { routes } from 'apps/auth/routes/routes'
import { useUserQuery } from 'apps/auth/context'
import { useAppliedActivitiesQuery, useUserActivitiesQuery } from 'apps/dashboard/context'
import { categories } from 'utils'

const dummyData = [
    {
        id: 1,
        title: 'Title',
        category: 'Category',
        quota: 'Quota',
        date: 'Date',
        status: 'Status'
    },
    {
        id: 2,
        title: 'Title 2',
        category: 'Category',
        quota: 'Quota',
        date: 'Date',
        status: 'Status'
    },
    {
        id: 3,
        title: 'Title 3',
        category: 'Category',
        quota: 'Quota',
        date: 'Date',
        status: 'Status'
    },
    {
        id: 4,
        title: 'Title 4',
        category: 'Category',
        quota: 'Quota',
        date: 'Date',
        status: 'Status'
    },
    {
        id: 5,
        title: 'Title 5',
        category: 'Category',
        quota: 'Quota',
        date: 'Date',
        status: 'Status'
    },
]

const users = [
    {
        id: 1,
        name: 'John Doe',
        email: 'johndoe@email.com',
        department: 'Department',
    },
    {
        id: 2,
        name: 'Jane Doe',
        email: 'janedoe@email.com',
        department: 'Department',
    },
    {
        id: 3,
        name: 'John Smith',
        email: 'johnsmith@email.com',
        department: 'Department',
    },
]

const Dashboard = () => {
    const { data } = useUserQuery({});
    const [variables, setVariables] = useState<any>({
        pagination: { page: 1, pageSize: 5, sort: '' },
        filter: { letsTitle: '' }
    })
    const { data: activities, error, isLoading } = useUserActivitiesQuery(variables)
    const {data: appliedActivities} = useAppliedActivitiesQuery({})


    return <DashboardLayout>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h1'>Dashboard </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} md={8} >
                <Card sx={{ p: 4 }}>
                    <Stack direction="row" gap={2} justifyContent="space-between">
                        <Stack direction={'column'} spacing={2} alignItems={'flex-start'} justifyContent={'center'}>
                            <Typography variant='h3' mb={2}>
                                Hi {data?.name}!  👋
                            </Typography>
                            <Typography>
                                Welcome to the dashboard. You can manage users, activities and view statistics from the sidebar.
                            </Typography>
                        </Stack>
                        <img src={HelloImage} alt="hello" width={200} style={{ objectFit: 'contain' }} />
                    </Stack>
                </Card>
            </Grid>
            <Grid item xs={12} md={4} >
                <Stack direction={"column"} alignItems={'space-between'} justifyContent={'space-between'} >
                    <Card sx={{ p: 3, width: '100%', mb: 3 }}>
                        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                            <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'}>
                                <Typography fontSize={30} fontWeight={600}>
                                    {activities?.count || 0}
                                </Typography>
                                <Typography fontSize={15} fontWeight={400}>
                                    Activities
                                </Typography>
                            </Stack>
                            <Box bgcolor="primary.lighter" p={2} borderRadius={2}>
                                <Typography>
                                    <Event sx={{ fontSize: '35px', color: 'primary.main' }} />
                                </Typography>
                            </Box>
                        </Stack>
                    </Card>
                    <Card sx={{ p: 3 }}>
                        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                            <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'}>
                                <Typography fontSize={30} fontWeight={600}>
                                    {appliedActivities?.count || 0}
                                </Typography>
                                <Typography fontSize={15} fontWeight={400}>
                                    Application
                                </Typography>
                            </Stack>
                            <Box bgcolor="secondary.lighter" p={2} borderRadius={2}>
                                <Typography>
                                    <Group sx={{ fontSize: '35px', color: 'secondary.main' }} />
                                </Typography>
                            </Box>
                        </Stack>
                    </Card>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card sx={{ p: 4 }}>
                    <Typography variant='h4' mb={2}>
                        Last Activities
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Quota</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                    activities?.data.map((item: any, index: any) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{item.title} </TableCell>
                                            <TableCell>{categories.find((category: any) => category.value === item?.letsTitle)?.label}</TableCell>
                                            <TableCell>{item.mainQuota}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Grid>

            {/* <Grid item xs={12} md={6} >
                <Card sx={{ p: 4 }}>
                    <Typography variant='h4' mb={2}>
                        Recent Users
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Name </TableCell>
                                    <TableCell>Department</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    users.map((data, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{data.id}</TableCell>
                                            <TableCell>{data.name}</TableCell>
                                            <TableCell>{data.department}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Grid> */}
        </Grid>
    </DashboardLayout >
}

export default Dashboard