
import { Label } from "@mui/icons-material";
import { Box, TextField as Field, FormControl, MenuItem, Typography } from "@mui/material"
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

interface ISelectField {
    values: any
    name: string
    setFieldValue: (field: string, value: any) => void
    datas: { value: string, label: string }[],
    placeholder?: string
}

export const SelectField = ({ values, name, placeholder, setFieldValue, datas }: ISelectField) => {

    return <Box sx={{ position: 'relative' }}>
        <Select
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? 'grey' : '#BBB',
                    backgroundColor: '#F4F6F8',
                    borderRadius: "18px",
                    minHeight: '56px'
                }),
                option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? '#F4F6F8' : 'white',
                    color: state.isFocused ? 'black' : 'black',
                }),
                menu: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: 'white',
                    zIndex: 9999
                }),

            }}
            getOptionLabel={(option) => option.label}
            placeholder={placeholder || "Select"}
            name="colors"
            isMulti
            components={makeAnimated()}
            options={datas}
            onChange={(value) => {
                setFieldValue(name, value)
            }}
            value={values[name]}
        />
    </Box>
}

