import { AuthRouter } from 'apps/auth/routes/AuthRouter';
import { AdminRouter } from 'apps/dashboard/routes/AdminRouter';
import { UserRouter } from 'apps/dashboard/routes/UserRouter';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'context';
import { setUser } from 'apps/auth/context';
import { LoadingPage } from 'components';
import { delay } from 'utils';


function Router() {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(state => state.auth);
  const [ready, setReady] = useState(false);

  // useEffect(() => {
  //   const auth = async () => {
  //     localStorage.getItem("auth") && dispatch(setUser(JSON.parse(localStorage.getItem("auth")!)));
  //   }

  //   auth();
  // }, [dispatch])

  useEffect(() => {
    const user = localStorage.getItem("auth");
    dispatch(setUser(JSON.parse(user ?? "{}")));
    delay(500).then(() => setReady(true));

  }, [dispatch]);



  const renderUserTypeRouter = () => {
    if (!ready) {
      return <Route path="*" element={<LoadingPage />} />
    }
    switch (role) {
      case "admin":
        return AdminRouter();
      case "user":
        return UserRouter();
      default:
        return AuthRouter()
      // <Route path="*" element={<LoadingPage />} />
    }
  }


  return (
    <BrowserRouter>
      <Routes>
        {renderUserTypeRouter()}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
