import { Box, Button, Container, Grid, Typography, Link, CircularProgress } from "@mui/material";
import Logo from "assets/hapag-logo.png";
import { useFormik } from "formik";
import { loginValidationSchema } from 'utils/validations';
import { routes } from "../routes/routes";
import { AuthLayout } from "../layouts";
import { useLoginMutation, setUser } from "../context";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "context";
import { getAccessToken } from "utils/getUserInfo";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { TextField } from "components";

const Login = () => {

  const [getLogin, { data, isLoading, error }] = useLoginMutation();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  getAccessToken();

  const state = useAppSelector((state: any) => state.auth);
  const dispatch = useAppDispatch();


  const { values, handleSubmit, setFieldValue, errors, handleBlur, touched } = useFormik({
    enableReinitialize: true,
    validationSchema: loginValidationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        await getLogin({ email: values.email, password: values.password });
      } catch (error) {
        enqueueSnackbar("Login Failed", { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar((error as any)?.error?.message?.en ?? "Something went wrong!", { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      enqueueSnackbar("Login Successfully", { variant: 'success' });
      dispatch(setUser(data));
    }
  }, [data, dispatch, enqueueSnackbar]);



  return <AuthLayout>
    <Grid item xs={12} md={6} display={"flex"} height={"100vh"} justifyContent={'center'} alignItems={'center'}>

      <Box display={{ xs: 'flex', md: 'none' }} height={100} />
      <Box
        sx={{
          width: { xs: '100%', md: '90%' },
          height: "80vh",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'center' },
          alignItems: 'center',
          gap: 5,
        }}
        px={{ xs: 1, md: 7 }}
      >
        <img src={Logo} alt="logo" width={220} />
        <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"}>
          <Typography fontSize={40} fontWeight={600} textAlign={"center"}>
            Welcome Back!
          </Typography>
          <Typography fontSize={20} fontWeight={400} textAlign={"center"}>
            Please login to your account
          </Typography>
        </Box>

        <Box display={"flex"} sx={{ width: '100%' }} flexDirection={"column"} alignItems={"center"}>
          <form onSubmit={handleSubmit as any} style={{ width: '100%' }}>
            <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} mb={2}>
              <TextField
                placeholder="Email"
                type="email"
                values={values}
                setFieldValue={setFieldValue}
                name="email"
                errors={errors}
                handleBlur={handleBlur}
                touched={touched}

              />
              <TextField
                placeholder="Password"
                type="password"
                values={values}
                setFieldValue={setFieldValue}
                name="password"
                errors={errors}
                handleBlur={handleBlur}
                touched={touched}

              />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                onClick={handleSubmit as any}
                disabled={isLoading}
              >
                {(isLoading) ? <CircularProgress size="1rem" /> : "Login"}

              </Button>
            </Box>
          </form>
          <Typography fontSize={15} fontWeight={500} >
            Can not login? <Link
              onClick={() => navigate(routes.auth.forgotPassword)}
              underline={"hover"}
              sx={{ cursor: 'pointer' }}
            >Forgot Password</Link>
          </Typography>

        </Box>
      </Box>
    </Grid>
  </AuthLayout >
}

export default Login;
