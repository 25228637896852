import { TextField } from "@mui/material";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers";

interface ITimeField {
    label: string;
    value: string;
    onClick: () => void;
}

interface IMultiSelectTimeField {
    value: any;
    onChange: (newValue: any) => void;
    minTime?: any;
}

export const TimeField = ({ label, value, onClick }: ITimeField) => {

    return <TextField
        fullWidth
        label={label}
        value={value}
        onClick={() => onClick()}
    />
}


export const MultiSelectTimeField = ({ value, onChange, minTime }: IMultiSelectTimeField) => {
    return <MultiSectionDigitalClock
        openTo='hours'
        ampm={false}
        value={value}
        onChange={onChange}
        sx={{ height: "130px" }}
        minTime={minTime || null}
    />
}