import ReactDOM from 'react-dom/client';
import './index.css';

import Wrapper from 'Wrapper';




const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);


root.render(<Wrapper />);

